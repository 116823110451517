import React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/color";
import { ReservedCourseItem } from "../../../types";
import { BreakpointsQuery } from "../../../constants/device";
import Label from "../../label";
import ContainerSeparator from "../../separator/container";
import Participants from "./participants";
import { useState } from "react";
import {getCourseIcon} from "../../../utils/utils";
import {ChevronDown} from "lucide-react";

const ReservedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1240px;
  max-width: 100%;
  align-items: center;
  border: none;
  outline-width: 0;

`;

const CourseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 6px 0 8px 4px ${Color.SHADOW};
  border-radius: 0.8rem;
  border-width: 40px;
  background-color: ${Color.WHITE};
  width: 1240px;
  height: 62px;
  z-index: 1;
  cursor: pointer;
  @media ${BreakpointsQuery.tablet} {
    width: 62rem;
  }
`;

const ContainerLeftBorder = styled.div`
  box-shadow: 0 0 8px 6px ${Color.SHADOW};
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  border-width: 2rem;
  background-color: ${Color.LIGHT_BLUE};
  width: 16px;
  height: 62px;
`;

const CourseTypeIcon = styled.div`
  margin-left: 10px;
  margin-right: 18px;
  width: 40px;
`;

const CourseTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  width: 600px;
`;

const LectureMode = styled.img`
  margin: 1rem;
  height: 60%;
`;

const CourseDate = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 98px;
`;

const CourseAddress = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0.6rem;
  text-align: center;
  width: 235px;
`;

const DetailButton = styled.button`
  position: relative;
  right: -2px;
  background-color: ${Color.GREEN};
  color: ${Color.WHITE};
  font-weight: bold;
  font-size: 14px;
  border: none;
  height: 40px;
  width: 96px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0.5rem 2rem;
`;

const Course = (course: ReservedCourseItem) => {
  const fontSize: string = "18px";
  const [open, setOpen] = useState(false);
  return (
    <ReservedContainer>
      <CourseContainer
          role={"button"}
          tabIndex={0}
          onClick={() => setOpen(!open)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setOpen(!open)
            }
          }}
      >
        <ContainerLeftBorder />
        <CourseTypeIcon>
          {getCourseIcon(course.type)}
        </CourseTypeIcon>
        <CourseTitle>
          <Label
            text={course.courseName}
            fontSize={fontSize}
            fontWeight={"bold"}
            textTransform={'uppercase'}
          />
          <Label
            text={course.courseLevel ?? ''}
            fontSize={fontSize}
            fontWeight={"normal"}
          />
        </CourseTitle>
        <ContainerSeparator />
        <LectureMode alt={'lecture-mode'} src={course.lectureMode} />
        <ContainerSeparator />
        <CourseDate>
          <Label
            text={course.courseDate}
            color={Color.GREY}
            fontSize={"14px"}
            textTransform={"uppercase"}
          />
          <Label
            text={course.courseHours}
            color={Color.GREY}
            fontSize={"14px"}
            width={'6rem'}
          />
        </CourseDate>
        <ContainerSeparator />
        <CourseAddress>
          <Label
            text={course.city}
            color={Color.GREY}
            fontSize={"14px"}
            textTransform={"uppercase"}
          />
          <Label text={course.address}
                 color={Color.GREY}
                 fontSize={"14px"}
                 textTransform={'capitalize'}
          />
        </CourseAddress>
        <DetailButton onClick={() => setOpen(!open)}>
          <ChevronDown color={Color.WHITE} size={32} style={{position: 'relative', bottom: '3px'}}/>
        </DetailButton>
      </CourseContainer>
      <Participants show={open} course={course} />
    </ReservedContainer>
  );
};

export default Course;
