import React from "react";
import styled from "styled-components";
//import {useTranslation} from "react-i18next";
import {Employee} from "../../../../../types";
import {Icons} from "../../../../../constants/icons";
import {Color} from "../../../../../constants/color";
import Label from "../../../../label";

interface Props {
  participant: Employee;
  edit: boolean;
  toBeRemoved: boolean;
  onDelete(): void | undefined;
}

const SingleParticipantsWrapper = styled.div <{
  $toBeRemoved: boolean;
}>`
  display: flex;
  align-items: center;
  min-width: 50rem;
  min-height: 2.6rem;
  background-color: ${props => props.$toBeRemoved ? Color.PINK : Color.LIGHT_GREY};
  border-style: solid;
  border-width: 1px;
  border-color: ${Color.GREY};
  border-radius: 0.8rem;
`;

const DeleteButton = styled.button<{
  $edit: boolean;
}>`
  visibility: ${($edit) => ($edit.$edit ? "unset" : "hidden")};
  flex-grow: 1;
  height: max-content;
  width: max-content;
  padding: 0;
  margin-right: 2rem;
  translate: 0 0.1rem;
  border-style: hidden;
  clip-path: circle(45% at 50% 50%);
`;

const DeleteButtonImage = styled.img`
  width: 1.4rem;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-grow: 400;
  justify-content: start;
  padding-left: 2rem;
`;

const SingleParticipant = ( { participant, edit, onDelete , toBeRemoved} : Props ) => {
    //const { t } = useTranslation();
    return (
        <SingleParticipantsWrapper $toBeRemoved={toBeRemoved}>
            <DeleteButton $edit={edit}
                          disabled={!edit}
                          onClick={ () => {
                              onDelete()
                          }}
            >
                <DeleteButtonImage alt={'delete'} src={Icons.BUTTONS.DELETE}/>
            </DeleteButton>
            <LabelContainer>
                <Label text={participant.lastName + ' ' + participant.firstName}
                       color={Color.DARK_GREY}
                       textAlign={'left'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'14px'}
                       width={'250px'}
                />
                <Label text={participant.workplace}
                       color={Color.DARK_GREY}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'14px'}
                       textAlign={'center'}
                       width={'200px'}
                />
                <Label text={participant.jobDescription}
                       color={Color.DARK_GREY}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'14px'}
                       textAlign={'center'}
                       width={'200px'}
                />
            </LabelContainer>
        </SingleParticipantsWrapper>
    );
};

export default SingleParticipant;
