import styled from "styled-components";
import Title from "../../components/title";
import { useTranslation } from "react-i18next";
import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import CustomSearch from "../../components/search";
import { DropdownOptions, Employee, EmployeesTableFilters } from "../../types";
import EmployeeTable from "../../components/employee-registry/table";
import Button from "../../components/button/button";
import { Icons } from "../../constants/icons";
import React, { useCallback, useEffect, useState } from "react";
import { getEmployeeByCompanyId } from "../../features/employee/utils";
import * as Ariakit from "@ariakit/react";
import {
  BE_BASE_URL,
  BE_TEMPLATE_DOWNLOAD_ENDPOINT,
  BE_IMPORT_EMPLOYEES_ENDPOINT,
} from "../../utils/config";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../../components/form/select";
import {
  initEmployeeStateFilter,
  initHeadquartersFilterEmployee,
  initJobDescriptionsFilter,
} from "../../utils/utils";
import { Status } from "../../constants/status";
import ModalEmployeeEdit from "../../components/employee-registry/modal-employee-edit";
import { axiosInstance } from "../../provider/authProvider";
import axios from "axios";

const handleDownloadTemplate = async () => {
  try {
    const response = await axiosInstance({
      url: BE_BASE_URL + BE_TEMPLATE_DOWNLOAD_ENDPOINT, // Il link del tuo endpoint backend
      method: "GET",
      responseType: "blob", // Questo è necessario per ricevere il file binario
    });

    // Creare un link temporaneo per scaricare il file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Dipendenti-Template.xlsx"); // Nome del file da scaricare
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Errore durante il download del template:", error);
  }
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
`;

const DropdownSection = styled.div`
  display: flex;
  column-gap: 0.8rem;
  background-color: ${Color.BLUE};
  padding: 1rem;
  border-radius: 1rem;
  align-items: flex-end;
  svg {
    position: relative;
    top: 1px;
    right: 2px;
  }
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    row-gap: 1rem;
  }
`;

const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem 0;
  width: 100%;
`;

const EmployeeRegistry = () => {
  const { t } = useTranslation();
  const [employeeList, setEmployeeList] = useState<Employee[]>();
  const [employeeListArray, setEmployeeListArray] = useState<Employee[]>(
    employeeList!
  );
  const [headquarters, setHeadquarters] = useState<Set<DropdownOptions>>();
  const [employeeStates, setEmployeeStates] = useState<Set<DropdownOptions>>();
  const [jobDescription, setJobDescriptions] = useState<Set<DropdownOptions>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null
  );

  const showEditModal = (employee: Employee) => {
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    // Aggiungi qui la logica per chiudere il modal
    setIsModalOpen(false);
    initPage();
  };

  const initPage = useCallback(() => {
    getEmployeeByCompanyId()!.then((response) => {
      setEmployeeList(response);
      setEmployeeListArray(response);
      initEmployeeStateFilter(setEmployeeStates, t);
      initHeadquartersFilterEmployee(response, setHeadquarters, t);
      initJobDescriptionsFilter(response, setJobDescriptions, t);
    });
  }, [t]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  const [filters, setFilters] = React.useState<EmployeesTableFilters>({
    state: t("book-course-filter-all"),
    lastNameAndFirstName: "",
    jobDescription: t("book-course-filter-all"),
    headquarter: t("book-course-filter-all"),
  });

  const filterCourse = useCallback(() => {
    let filtered = employeeList!;
    if (filters.state !== t("book-course-filter-all")) {
      filtered = filtered.filter(
        (el) => Status.get(el.state)?.label === filters.state
      );
    }
    if (filters.lastNameAndFirstName !== "") {
      filtered = filtered.filter((el) =>
        (el.lastName.toLowerCase() + " " + el.firstName.toLowerCase()).includes(
          filters.lastNameAndFirstName
        )
      );
    }
    if (filters.jobDescription !== t("book-course-filter-all")) {
      filtered = filtered.filter(
        (el) => el.jobDescription === filters.jobDescription
      );
    }
    if (filters.headquarter !== t("book-course-filter-all")) {
      filtered = filtered.filter((el) =>
        el.workplace.toLowerCase().includes(filters.headquarter.toLowerCase())
      );
    }
    setEmployeeListArray(filtered);
  }, [
    employeeList,
    filters.headquarter,
    filters.jobDescription,
    filters.lastNameAndFirstName,
    filters.state,
    t,
  ]);

  const selectEmployeeState = Ariakit.useSelectStore({
    value: filters.state.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, state: value }));
    },
  });

  const selectJobDescription = Ariakit.useSelectStore({
    value: filters.jobDescription.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, jobDescription: value }));
    },
  });

  const selectHeadquarter = Ariakit.useSelectStore({
    value: filters.headquarter.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, headquarter: value }));
    },
  });

  const handleImportFile = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";

    input.onchange = async (e) => {
      const target = e.target as HTMLInputElement;
      if (target && target.files) {
        const file = target.files[0];
        if (file) {
          const formData = new FormData();
          formData.append("file", file);

          try {
            const response = await axiosInstance.post(
              BE_BASE_URL + BE_IMPORT_EMPLOYEES_ENDPOINT + "/excel",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("File caricato con successo:", response.data);
            // Aggiungi un messaggio di successo (opzionale)
            alert("File caricato con successo!");
            // Aggiorna la pagina
            window.location.reload();
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // Verifica se esiste error.response.data.message
              const errorMessage = error.response?.data?.message
                ? error.response.data.message
                : "Si è verificato un errore durante il caricamento del file.";

              console.error(
                "Errore durante il caricamento del file:",
                errorMessage
              );
              alert(errorMessage);
              window.location.reload();
            } else {
              // Gestisci altri tipi di errore, se necessari
              console.error("Errore sconosciuto:", error);
              alert(
                "Si è verificato un errore sconosciuto durante il caricamento del file."
              );
            }
          }
        }
      }
    };

    input.click();
  };

  useEffect(() => {
    filterCourse();
  }, [filterCourse, filters]);

  const newEmployee: Employee = {
    state: 0,
    firstName: "",
    lastName: "",
    cf: "",
    jobDescription: "",
    workplace: "",
    hireDate: new Date(),
    email: "",
    phone: "",
    description: "",
  };

  return (
    <PageWrapper>
      <Title text={t("employee-registry-title")} fontSize={"20px"} />
      {employeeList && (
        <DropdownSection>
          {employeeStates && (
            <SelectProvider store={selectEmployeeState}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <SelectLabel
                  style={{
                    paddingLeft: "0.5rem",
                    fontSize: "16px",
                    color: Color.WHITE,
                    textTransform: "uppercase",
                    fontFamily: "Avenir Next Bold, sans-serif",
                  }}
                >
                  {t("employee-registry-dropdown-state")}
                </SelectLabel>
                <Select
                  style={{
                    minWidth: "200px",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "20px",
                    borderColor: Color.LIGHT_BLUE,
                    padding: "0",
                    paddingLeft: "14px",
                    height: "30px",
                    fontFamily: "Avenir Next Regular, sans-serif",
                  }}
                  renderValue={(value) =>
                    Array.from(employeeStates).find(
                      (status) => status.value === value
                    )?.label
                  }
                >
                  {Object.entries(Array.from(employeeStates)).map(
                    ([key, value]) => (
                      <SelectItem
                        key={`status_${key}`}
                        value={value.label}
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {value.label}
                      </SelectItem>
                    )
                  )}
                </Select>
              </div>
            </SelectProvider>
          )}
          <CustomSearch
            name={t("reserved-courses-detail-header-name")}
            label={t("reserved-courses-detail-search-placeholder")}
            labelProps={{
              color: Color.WHITE,
              text: t("reserved-courses-detail-header-name"),
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "bold",
              width: "260px",
            }}
            width={"260px"}
            onSearch={(e) => {
              setFilters((prev) => ({ ...prev, lastNameAndFirstName: e }));
            }}
          />
          {jobDescription && (
            <SelectProvider store={selectJobDescription}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <SelectLabel
                  style={{
                    paddingLeft: "0.5rem",
                    fontSize: "16px",
                    color: Color.WHITE,
                    textTransform: "uppercase",
                    fontFamily: "Avenir Next bold, sans-serif",
                  }}
                >
                  {t("reserved-courses-detail-header-job-description")}
                </SelectLabel>
                <Select
                  style={{
                    minWidth: "200px",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "20px",
                    borderColor: Color.LIGHT_BLUE,
                    padding: "0",
                    paddingLeft: "14px",
                    height: "30px",
                    fontFamily: "Avenir Next Regular, sans-serif",
                  }}
                  renderValue={(value) =>
                    Array.from(jobDescription).find(
                      (cat) => cat.value === value
                    )?.label
                  }
                >
                  {Object.entries(Array.from(jobDescription)).map(
                    ([key, value]) => (
                      <SelectItem
                        key={`jobDescription_${key}`}
                        value={value.value}
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {value.label}
                      </SelectItem>
                    )
                  )}
                </Select>
              </div>
            </SelectProvider>
          )}
          {headquarters && (
            <SelectProvider store={selectHeadquarter}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <SelectLabel
                  style={{
                    paddingLeft: "0.5rem",
                    fontSize: "16px",
                    color: Color.WHITE,
                    textTransform: "uppercase",
                    fontFamily: "Avenir Next Bold, sans-serif",
                  }}
                >
                  {t("book-course-search-headquarter")}
                </SelectLabel>
                <Select
                  style={{
                    minWidth: "200px",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "20px",
                    borderColor: Color.LIGHT_BLUE,
                    padding: "0",
                    paddingLeft: "14px",
                    height: "30px",
                    fontFamily: "Avenir Next Regular, sans-serif",
                    textTransform: "capitalize",
                  }}
                  renderValue={(value) =>
                    Array.from(headquarters).find(
                      (city) => city.value === value
                    )?.label
                  }
                >
                  {Object.entries(Array.from(headquarters)).map(
                    ([key, value]) => (
                      <SelectItem
                        key={`city_${key}`}
                        value={value.label}
                        style={{
                          justifyContent: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {value.label}
                      </SelectItem>
                    )
                  )}
                </Select>
              </div>
            </SelectProvider>
          )}
        </DropdownSection>
      )}
      {employeeListArray && (
        <EmployeeTable
          employees={employeeListArray}
          showEditModal={showEditModal}
        />
      )}
      <ModalEmployeeEdit
        isVisible={isModalOpen}
        employee={selectedEmployee}
        closeModal={closeEditModal}
        isNewEmployee={!selectedEmployee?.id}
      />
      <EditButtonsContainer>
        <Button
          label={t("employee-registry-button-add-employee")}
          color={Color.GREEN}
          icon={Icons.BUTTONS.PLUS}
          onClick={() => {
            showEditModal(newEmployee);
          }}
        />
        <Button
          label={t("employee-registry-button-import-file")}
          color={Color.BLUE}
          icon={Icons.BUTTONS.UPLOAD}
          onClick={handleImportFile}
        />
        <Button
          label={t("employee-registry-button-download-template")}
          color={Color.LIGHT_BLUE}
          icon={Icons.BUTTONS.DOWNLOAD}
          onClick={handleDownloadTemplate} // Aggiungi l'evento onClick
        />
      </EditButtonsContainer>
    </PageWrapper>
  );
};
export default EmployeeRegistry;
