import React from "react";
import { CourseDate } from "../../../types";
import CourseDateItem from "./date";
import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DateDetail from "../../book-course/carousel/item/detail";
import { mapCourseDate } from "../../../utils/utils";

interface Props {
  dates: Set<CourseDate>;
  setVariationId: (variation: number) => void;
  setShowModal: () => void;
}

const CarouselWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const CourseDateCarousel = ({ dates, setVariationId, setShowModal }: Props) => {
  const { dateId } = useParams();
  const mappedDate: Map<number, CourseDate[]> = mapCourseDate(dates);
  const [selected, setSelected] = useState(Number(dateId));
  const handleSelected = (id: number) => setSelected(id);
  return (
    <>
      <CarouselWrapper>
        {Array.from(mappedDate.values()).map((singleVariation) => {
          return (
            <CourseDateItem
              date={singleVariation}
              key={singleVariation[0].variationId}
              index={singleVariation[0].variationId}
              selected={selected === singleVariation[0].variationId}
              onClick={handleSelected}
            />
          );
        })}
      </CarouselWrapper>
      {mappedDate.get(selected) && (
        <DateDetail
          show={selected !== -1}
          date={mappedDate.get(selected)!}
          setVariationId={setVariationId}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default CourseDateCarousel;
