import React from "react";
import styled from "styled-components";
import { LabelProps } from "../../types";
import { Color } from "../../constants/color";

const StyledLabel = styled.div <{
        $color?: string,
        $textTransform?: string,
        $textAlign?: string,
        $fontSize?: string,
        $fontWeight?: string,
        $paddingLeft?: number,
        $paddingBottom?: number,
        $width?: string,
        $height?: string
    }>`
    color: ${$props => $props.$color ?? Color.LIGHT_BLUE};
    font-family: ${$props => $props.$fontWeight === 'bold' ? 'Avenir Next Bold, sans-serif' : 'Avenir Next, sans-serif'};
    font-size: ${$props => $props.$fontSize ?? 'unset'};
    font-weight: ${$props => $props.$fontWeight ?? 'bold'};
    text-align: ${$props => $props.$textAlign ?? 'unset'};
    text-transform: ${$props => $props.$textTransform ?? 'none'};
    padding-left: ${$props => $props.$paddingLeft ? $props.$paddingLeft+'px' : '0'};
    padding-bottom: ${$props => $props.$paddingBottom ? $props.$paddingBottom+'px' : '0'};
    width: ${$props => $props.$width ?? 'unset'};
    height: ${$props => $props.$height ?? 'unset'};
`;

const Label = ({color, text, textAlign, textTransform, fontSize , fontWeight, paddingLeft, paddingBottom, width, height, style}: LabelProps) => {
    return (
        <StyledLabel
            $color={color}
            $textTransform={textTransform}
            $textAlign={textAlign}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $paddingLeft={paddingLeft}
            $paddingBottom={paddingBottom}
            $width={width}
            $height={height}
            style={style}
        >
            {text}
        </StyledLabel>
    )
}

export default Label;
