import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";

import Combobox from "../combobox";
import Select from "../select";
import { Color } from "../../constants/color";
import {CorsoType, DashboardSummary, LabelValueType} from "../../types";
import { BE_BASE_URL } from "../../utils/config";
import {axiosInstance} from "../../provider/authProvider";

function CorsiBarChart({
  chartWidth,
  chartHeight,
}: {
  chartWidth: string;
  chartHeight: string;
}) {
  const { t } = useTranslation();

  const mapMonths = new Map<number, string>(
    Array.from({ length: 12 }, (_, i) => [i + 1, t(`months.${i + 1}`)])
  );
  const monthsOptions: LabelValueType[] = [];
  mapMonths.forEach((v, k) => {
    monthsOptions.push({
      label: v,
      value: `${k}`,
    });
  });

  const [domainTables, setDomainTables] = useState<{
    corsi: CorsoType[];
    sedi: string[];
  }>({
    corsi: [],
    sedi: [],
  });
  const [chartData, setChartData] = useState<DashboardSummary>();
  const [filters, setFilters] = useState<{ corsi: string[]; sedi: string[] }>({
    corsi: [],
    sedi: [],
  });

  const readCorsi = useCallback(async () => {
    const response = await axiosInstance.get(`${BE_BASE_URL}/courses`);
    return response.data;
  }, []);
  const readSedi = useCallback(async () => {
    const response = await axiosInstance.get(`${BE_BASE_URL}/locations`);
    return response.data;
  }, []);
  const readChartData = useCallback(async (filters: {
    corsi: string[];
    sedi: string[];
  }) => {
    const sParams = new URLSearchParams();
    sParams.append("companyId", "2");
    if (filters.corsi.length > 0) {
      sParams.append("courseId", filters.corsi.join(","));
    }
    if (filters.sedi.length > 0) {
      sParams.append("location", filters.sedi.join(","));
    }
    const response = await axiosInstance.get<DashboardSummary>(`${BE_BASE_URL}/dashboard?${sParams}`);
    if (response && response.status === 200) {
      return response.data;
    }
    return { requirements: 0, completed: 0 };
  }, []);

  useEffect(() => {
    Promise.all([readCorsi(), readSedi(), readChartData(filters)])
      .then(([corsi, sedi, cData]) => {
        setDomainTables({
          corsi,
          sedi,
        });
        setChartData(cData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
     
  }, [filters, readChartData, readCorsi, readSedi]);

  useEffect(() => {
    readChartData(filters).then((cData) => {
      setChartData(cData);
    });
  }, [filters, readChartData]);

  const corsi = domainTables?.corsi
    ? domainTables.corsi.map((c) => {
        return {
          label: `[${c.code}] ${c.name}`,
          value: `${c.id}`,
        };
      })
    : [];

  const sedi = domainTables?.sedi
    ? domainTables.sedi.map((s) => {
        return {
          label: s,
          value: s,
        };
      })
    : [];

  function handleFiltroCorsiChange(filter: LabelValueType[]) {
    //console.log("Corsi chart filtro corso cambiato", filter);
    setFilters({
      ...filters,
      corsi: filter.map((f) => f.value),
    });
  }

  function handleFiltroSediChange(filter: LabelValueType[]) {
    //console.log("Corsi chart filtro sede cambiato", filter);
    setFilters({
      ...filters,
      sedi: filter.map((f) => f.value),
    });
  }

  function calcolaTasso() {
    if (!chartData) return 0;
    if (chartData.requirements === 0) return "100%";
    return (
      ((chartData.completed / chartData.requirements) * 100).toFixed(0) + "%"
    );
  }

  return (
    <div
      style={{
        padding: "1rem .5rem",
        color: Color.BLUE,
        fontWeight: "bold",
      }}
    >
      <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>
        {t("dashboard-chart-courses")}
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: ".1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-around",
            gap: ".1em",
            paddingBlock: ".1em",
            width: "100%",
          }}
        >
          <Combobox
            options={corsi}
            placeholder="Filtri corsi"
            onChange={handleFiltroCorsiChange}
          />
          <Combobox
            options={sedi}
            placeholder="Filtri sedi"
            onChange={handleFiltroSediChange}
          />
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Select
              label="Periodo"
              placeholder="Inizio"
              options={monthsOptions}
            />
          </div>
          <Select placeholder="Fine" options={monthsOptions} />
        </div>
        {/* CHART CORSI - CORSO */}
        <div
          style={{
            display: "flex",
            gap: "1em",
            width: chartWidth,
            height: chartHeight,
            padding: "1em .5em",
            alignSelf: "center",
          }}
        >
          <ResponsiveContainer width="50%" height="100%">
            <BarChart
              width={800}
              height={300}
              data={[{ name: "corsi", ...chartData }]}
              margin={{ bottom: 10 }}
            >
              <CartesianGrid vertical={false} />
              <YAxis />
              <Bar dataKey="completed" fill={Color.GREEN} />
              <Bar dataKey="requirements" fill={Color.LIGHT_BLUE} />
            </BarChart>
          </ResponsiveContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              gap: "1em",
              padding: "1em",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  backgroundColor: Color.GREEN,
                }}
              />
              <div>Corsi svolti</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  backgroundColor: Color.LIGHT_BLUE,
                }}
              />
              <div>Corsi da svolgere</div>
            </div>
            <p>Tasso di formazione: {calcolaTasso()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorsiBarChart;
