import React from "react";
import {
  Select,
  SelectArrow,
  SelectItem,
  SelectItemCheck,
  SelectLabel,
  SelectPopover,
  SelectProvider,
  useSelectStore,
} from "@ariakit/react";
import { useState } from "react";

import "./combobox_style.css";
import styled from "styled-components";
import { XCircle } from "lucide-react";
import { Color } from "../../constants/color";
import { LabelValueType } from "../../types";

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--white);
  border: 0px solid var(--grey300);
  border-radius: 4px;
  height: 100%;
  font-size: 0.875rem;
`;

const TagLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
`;

const Sep = styled.span`
  width: 1px;
  border-left: 1px solid var(--grey500);
  height: 50%;
  ${Tag}:first-child & {
    display: none;
  }
`;

function TagElement({
  label,
  value,
  onClick,
}: {
  label: string;
  value: string;
  onClick: (v: string) => void;
}) {
  return (
    <Tag>
      <Sep />
      <TagLabel>{label}</TagLabel>
      <XCircle
        color={Color.BLUE}
        width={16}
        height={16}
        onClick={(e) => {
          e.stopPropagation();
          onClick(value);
        }}
        style={{
          flexShrink: 0,
        }}
      />
    </Tag>
  );
}

export default function Combobox({
  label,
  placeholder = "Seleziona un'opzione",
  options,
  onChange,
}: {
  label?: string;
  placeholder?: string;
  options: LabelValueType[];
  onChange?: (value: LabelValueType[]) => void;
}) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const select = useSelectStore({
    value: selectedItems,
    setValue: (value) => {
      // console.log(`log ${value}`);
      setSelectedItems(value);
      if (onChange)
        onChange(value.map((v) => options.find((o) => o.value === v)!));
    },
  });

  function renderValue(value: string[]) {
    // console.log("combobox render value", value);
    if (value.length === 0) return placeholder;
    if (value.length <= 1) {
      return value.map((v) => {
        const oIndex = options.findIndex((o) => o.value === v);
        return (
          <TagElement
            key={v}
            label={options[oIndex].label}
            value={v}
            onClick={(v) => {
              // console.log("clicked on ", v);
              const newSelection = selectedItems.filter((i) => i !== v);
              select.setValue(newSelection);
            }}
          />
        );
      });
    } else {
      return <Tag style={{ width: "100%" }}>{value.length} selezionati</Tag>;
    }
  }

  return (
    <div className="wrapper">
      <SelectProvider store={select}>
        {label && <SelectLabel>{label}</SelectLabel>}
        <Select className="button">
          {renderValue(selectedItems)}
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <Sep />
            <SelectArrow />
          </div>
        </Select>
        <SelectPopover
          gutter={4}
          sameWidth={false}
          unmountOnHide
          className="popover"
        >
          {options.map((o) => (
            <SelectItem key={o.value} value={o.value} className="select-item">
              <SelectItemCheck
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
              {o.label}
            </SelectItem>
          ))}
        </SelectPopover>
      </SelectProvider>
    </div>
  );
}
