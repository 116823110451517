import React, { useEffect } from "react";
import Title from "../../components/title";
import { useTranslation } from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {storeEmployees} from "../../features/employee/employeeSlice";

const NotFound = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(storeEmployees([]))
  }, [dispatch]);

  return (
    <Title text={t('not-found')}/>
  );
};

export default NotFound