import React from "react";
import type { ReactNode } from "react";
import * as Separator from "@radix-ui/react-separator";
import styled from "styled-components";

type SizeProp = "sm" | "md" | "lg";
type KpiIconProps = {
  bgColor: string;
  size: SizeProp;
};

const KpiLayout = styled.div<{ $h: string; $gap: string; $vertical: boolean }>`
  display: flex;
  flex-direction: ${($props) => ($props.$vertical ? "column" : "row")};
  align-items: center;
  text-align: ${($props) => ($props.$vertical ? "center" : "inherit")};
  gap: ${($props) => $props.$gap};
  height: ${($props) => $props.$h};
`;

const IconWrapper = styled.div<{ $bgColor: string; $size: SizeProp }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${($props) => $props.$bgColor ?? "transparent"};
  border-radius: 8px;
  width: ${($props) =>
    $props.$size === "sm" ? "32px" : $props.$size === "md" ? "48px" : "64px"};
  height: ${($props) =>
    $props.$size === "sm" ? "32px" : $props.$size === "md" ? "48px" : "64px"};
`;

const StyledSeparator = styled(Separator.Root)<{
  $orientation: "vertical" | "horizontal";
  $color: string;
}>`
  background-color: ${($props) => $props.$color};
  ${($props) =>
    $props.$orientation === "vertical"
      ? "width: 1px; height: 100%;"
      : "width: 100%; height: 1px;"}
`;

function Kpi({
  h,
  gap,
  vertical,
  children,
}: {
  h: string;
  gap: string;
  vertical: boolean;
  children: ReactNode;
}) {
  return (
    <KpiLayout $h={h} $gap={gap} $vertical={vertical}>
      {children}
    </KpiLayout>
  );
}

function KpiIcon({
  bgColor,
  size,
  children,
}: KpiIconProps & { children: ReactNode }) {
  return (
    <IconWrapper $bgColor={bgColor} $size={size}>
      {children}
    </IconWrapper>
  );
}

function KpiSeparator({
  orientation,
  color,
}: {
  orientation: "vertical" | "horizontal";
  color: string;
}) {
  return <StyledSeparator $orientation={orientation} $color={color} />;
}

export { Kpi, KpiIcon, KpiSeparator };
