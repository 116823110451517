import React from "react";
import styled from "styled-components";
import { Employee } from "../../../../types";
import { Color } from "../../../../constants/color";
import Label from "../../../label";

interface Props {
  employee: Employee;
}

const SingleParticipantsWrapper = styled.td`
  display: flex;
  align-items: center;
  min-height: 2.6rem;
  border-bottom: 1px solid ${Color.LIGHT_BLUE};
  &:last-child {
    border-bottom: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-grow: 400;
  justify-content: start;
  padding-left: 1rem;
`;

const SingleEmployee = ({ employee }: Props) => {
  return (
    <SingleParticipantsWrapper>
      <LabelContainer>
        <Label
          text={employee.lastName}
          color={Color.LIGHT_BLUE}
          textAlign={"left"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"12rem"}
        />
        <Label
          text={employee.firstName}
          color={Color.LIGHT_BLUE}
          textAlign={"left"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"10rem"}
        />
        <Label
          text={employee.jobDescription}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"12rem"}
        />
        <Label
          text={employee.workplace}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          textAlign={"center"}
          width={"17rem"}
        />
        <Label
          text={employee.email}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"10px"}
          textAlign={"left"}
          width={"14rem"}
        />
        <Label
          text={employee.phone}
          color={Color.LIGHT_BLUE}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          textAlign={"center"}
          width={"5rem"}
        />
      </LabelContainer>
    </SingleParticipantsWrapper>
  );
};

export default SingleEmployee;
