import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import Login from "./pages/login";
import UserPage from "./pages/user";
import PreviousCertificatesPage from "./pages/previous-certificates";
import TrainingNeedsPage from "./pages/training-needs";
import ReservedCourses from "./pages/reserved-courses";
import NavBar from "./components/navbar";
import "./localizations/i18nComponent";
import AuthProvider from "./provider/authProvider";
import { ProtectedRoute } from "./routes/protected-route";
import BookCourse from "./pages/book-course";
import Course from "./pages/course";
import EmployeeRegistry from "./pages/employee-registry";
import AttestatiPage from "./pages/certificates";
import styled from "styled-components";
import ResetPassword from "./pages/reset-password";
import NotFound from "./pages/404";
import GeneralError from "./pages/500";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 229px);
  margin-bottom: 80px;
`;

function App() {
  return (
    <AuthProvider>
      <Layout>
        <NavBar />
        {/*<Menu/>*/}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/500" element={<GeneralError />} />
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/user" element={<UserPage />} />
            <Route
              path="/previous-certificates"
              element={<PreviousCertificatesPage />}
            />
            <Route path="/training-needs" element={<TrainingNeedsPage />} />
            <Route path="/prenota-un-corso" element={<BookCourse />} />
            <Route path="/corsi-prenotati" element={<ReservedCourses />} />
            <Route path="/corso/:courseId" element={<Course />} />
            <Route path="/corso/:courseId/:dateId" element={<Course />} />
            <Route
              path="/corso/:courseId/:dateId/:selectedEmployees"
              element={<Course />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/anagrafica-dipendenti"
              element={<EmployeeRegistry />}
            />
            <Route path="/attestati" element={<AttestatiPage />} />
          </Route>
        </Routes>
      </Layout>
      {/*<Footer />*/}
    </AuthProvider>
  );
}

export default App;
