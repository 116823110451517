import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Label from "../../label";
import Textbox from "../../form/input";
import {Color} from "../../../constants/color";
import {BreakpointsQuery} from "../../../constants/device";
import {Headquarter, UserPageResponse} from "../../../types";
import Button from "../../button/button";
import {Icons} from "../../../constants/icons";
import React, {ChangeEvent, useEffect, useState} from "react";

interface Props {
  show: boolean;
  edit: boolean;
  companyData: UserPageResponse | undefined;
  editCompanyData: (field: string, event: ChangeEvent<HTMLInputElement>, index: number, headquarters?: Set<Headquarter>) => void;
}

const MainContainer = styled.div <{
    $show: boolean;
}>`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const InfoContainer = styled.div <{
  $edit: boolean;

}>`
    display: flex;
    flex-direction: row;
    column-gap: 80px;
    margin-top: 30px;
    position: ${props => props.$edit ? 'relative' : 'unset'};
    right: ${props => props.$edit ? '66px' : 'unset'};
    input {
        background-color: ${Color.WHITE};
        box-shadow: none;
        border-style: solid;
        border-width: 2px;
        border-color: ${Color.LIGHT_BLUE};
        border-radius: 20px;
        width: 480px;
        height: 30px;
        @media ${BreakpointsQuery.tablet} {
            width: 400px;
        }
    }
    input:focus {
        outline-width: 0;
    }
    @media ${BreakpointsQuery.tablet} {
        column-gap: 20px;
        right: ${props => props.$edit ? '35px' : 'unset'};
    }
`;

const Row = styled.div`
    display: flex;
    column-gap: 80px;
    margin-top: 30px;
    input {
        background-color: ${Color.WHITE};
        box-shadow: none;
        border-style: solid;
        border-width: 2px;
        border-color: ${Color.LIGHT_BLUE};
        border-radius: 20px;
        width: 480px;
        height: 30px;
        @media ${BreakpointsQuery.tablet} {
            width: 400px;
        }
    }
    input:focus {
        outline-width: 0;
    }
    @media ${BreakpointsQuery.tablet} {
        column-gap: 20px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
`;

const RemoveButtonContainer = styled.div <{
    $edit: boolean;
}>`
    display: ${props => props.$edit ? 'unset' : 'none'};
    margin-top: 24px;
    width: min-content;
    position: relative;
    left: 65px;
    @media ${BreakpointsQuery.tablet} {
        left: 5px;
    }
`;

const ButtonContainer = styled.div <{
    $edit: boolean;
}>`
    display: ${props => props.$edit ? 'unset' : 'none'};
    margin-top: 30px;
`;

const Headquarters = ({show, edit, companyData ,editCompanyData} : Props) => {
  const { t } = useTranslation();
  const [headquarters, setHeadquarters] = useState<Set<Headquarter>>()

  useEffect(() => {
    setHeadquarters(companyData?.headquarters)
  }, [companyData]);


  return (
      <MainContainer $show={show}>
        <Label text={t('user-headquarters-label')}
               fontSize={'26px'}
               fontWeight={'bold'}
               textTransform={'uppercase'}
        />
        <Row>
          <Textbox label={t('user-registered-office-address')}
                   alt={t('user-registered-office-address')}
                   autocomplete={'off'}
                   form={'company-info'}
                   name={t('user-registered-office-address')}
                   placeholder={companyData ? companyData.companyInfo.registeredOfficeAddress : ''}
                   type={'text'}
                   textAlign={'left'}
                   onChange={(e) => {
                     editCompanyData(`headquarter.registeredOfficeAddress`, e, -1)
                   }}
                   required={false}
                   edit={edit}
          />
          <Textbox label={t('user-registered-office-telephone')}
                   alt={t('user-registered-office-telephone')}
                   autocomplete={'off'}
                   form={'company-info'}
                   name={t('user-registered-office-telephone')}
                   placeholder={companyData ? companyData.companyInfo.registeredOfficeTelephone : ''}
                   type={'text'}
                   textAlign={'left'}
                   onChange={(e) => {
                     editCompanyData(`headquarter.registeredOfficeTelephone`, e, -1)
                   }}
                   required={false}
                   edit={edit}
          />
        </Row>
        {headquarters && Array.from(headquarters).map((headquarter, index) => {
          return (
          <InfoContainer key={index} $edit={edit}>
            <RemoveButtonContainer $edit={edit}>
              <Button color={Color.RED}
                      textColor={Color.WHITE}
                      icon={Icons.BUTTONS.CANCEL}
                      onClick={() => {
                        const newHeadquarters : Array<Headquarter> = Array.from(headquarters!).filter(el => el !== Array.from(headquarters)[index])
                        setHeadquarters(new Set(newHeadquarters))
                      }}
              />
            </RemoveButtonContainer>
            <Column>
              <Textbox label={t('user-operating-office-address') + ' ' + (index + 1)}
                       alt={t('user-operating-office-address')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-operating-office-address')}
                       placeholder={headquarter.operativeOfficeAddress}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData(`headquarter.operativeOfficeAddress`, e, index, headquarters)
                       }}
                       required={false}
                       edit={edit}
              />
            </Column>
            <Column>
              <Textbox label={t('user-operating-office-telephone') + ' ' + (index + 1)}
                       alt={t('user-operating-office-telephone')}
                       autocomplete={'off'}
                       form={'company-info'}
                       name={t('user-operating-office-telephone')}
                       placeholder={headquarter.operativeOfficeTelephone}
                       type={'text'}
                       textAlign={'left'}
                       onChange={(e) => {
                         editCompanyData(`headquarter.operativeOfficeTelephone`, e, index, headquarters)
                       }}
                       required={false}
                       edit={edit}
              />
            </Column>
          </InfoContainer>
          );
          })}
          <ButtonContainer $edit={edit}>
            <Button color={Color.LIGHT_BLUE}
                    textColor={Color.WHITE}
                    icon={Icons.BUTTONS.PLUS}
                    label={t('user-add-operative-office')}
                    onClick={() => {
                      const headquarter : Headquarter = { operativeOfficeAddress: ' ', operativeOfficeTelephone: ' '}
                      const newHeadquarters : Array<Headquarter> = Array.from(headquarters!)
                      newHeadquarters.push(headquarter)
                      setHeadquarters(new Set(newHeadquarters))
                    }}
            />
          </ButtonContainer>
      </MainContainer>
  );
}

export default Headquarters