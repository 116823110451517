import { AxiosError } from "axios";
import { UserPageResponse, UserType } from "../../types";
import { BE_BASE_URL, BE_COMPANY_ENDPOINT } from "../../utils/config";
import { Dispatch } from "react";
import { storeUser } from "./userSlice";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { PersistPartial } from "redux-persist/es/persistReducer";
import { axiosInstance } from "../../provider/authProvider";
import { goToPage } from "../../utils/utils";

export function getCompanyInfoByUser() {
  try {
    const axiosResponse = axiosInstance.get<UserPageResponse>(
      BE_BASE_URL + BE_COMPANY_ENDPOINT + "getByUser"
    );
    return axiosResponse.then((response) => response.data);
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage("500");
  }
}

export function setCompanyInfoByUser(newUserData: UserPageResponse) {
  try {
    const axiosResponse = axiosInstance.post<UserPageResponse>(
      BE_BASE_URL + BE_COMPANY_ENDPOINT + "editByUser",
      {
        adminFirstName: newUserData.adminFirstName,
        adminLastName: newUserData.adminLastName,
        adminPhone: newUserData.adminPhone,
        adminEmail: newUserData.adminEmail,
        businessName: newUserData.businessName,
        vatNumber: newUserData.vatNumber,
        cem: newUserData.cem,
        rescueAnswer1: newUserData.rescueAnswer1,
        rescueAnswer2: newUserData.rescueAnswer2,
        tdEmployees: newUserData.tdEmployees,
        tiEmployees: newUserData.tiEmployees,
        totEmployees: newUserData.totEmployees,
        companyInfo: newUserData.companyInfo,
        headquarters: Array.from(newUserData.headquarters),
      }
    );
    return axiosResponse.then((response) => response);
  } catch (e: unknown) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("error if", err);
    } else {
      console.log("error else", err);
    }
  }
}

export async function revalidateToken(
  setToken: (newToken: string) => void,
  dispatch: ThunkDispatch<
    { user: UserType; employee: string[] } & PersistPartial,
    undefined,
    UnknownAction
  > &
    Dispatch<UnknownAction>
): Promise<number> {
  let status: number = 401;
  try {
    await axiosInstance
      .get(BE_BASE_URL + "/auth/revalidate")
      .then((response) => {
        dispatch(
          storeUser({
            username: response.data.username,
            email: response.data.email,
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            role: response.data.role,
            phone: response.data.phone,
          })
        );
        setToken(response.data.token);
        status = response.status;
        return status;
      });
    return status;
  } catch (e: unknown) {
    const err = e as AxiosError;
    if (err.response && err.response.data && err.message) {
      console.log("token refresh error", err);
      status = err.status ?? 401;
      return status;
    } else {
      console.log("token refresh error", err);
      status = err.status ?? 401;
      return status;
    }
  }
}
