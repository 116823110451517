import React, {ChangeEvent} from 'react';
import styled from "styled-components";
import Title from "../../components/title";
import {useTranslation} from "react-i18next";
import ProfileHeader from "../../components/user/header";
import MainInfo from "../../components/user/main-info";
import SecondInfo from "../../components/user/second-info";
import Headquarters from "../../components/user/headquarters";
import {Color} from "../../constants/color";
import Button from "../../components/button/button";
import {Icons} from "../../constants/icons";
import Label from "../../components/label";
import {useEffect, useState} from "react";
import {Headquarter, UserPageResponse} from "../../types";
import {getCompanyInfoByUser, setCompanyInfoByUser} from "../../features/user/utils";

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
`;

const ExpandContainer = styled.div <{
    $show: boolean;
}>`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Color.BLUE};
    width: 100%;
    height: 4px;
    margin-top: 20px;
`;

const ExpandButton = styled.div`
    button {
        background-color: ${Color.BLUE};
        width: 100px;
        height: 20px;
        border-radius: 20px;
    }
    padding-bottom: 18px
`;

const EditButtonGroup = styled.div <{
    $show: boolean;
}>`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
`;

const UserPage = () => {
  const { t } = useTranslation();
  const [showSecondInfo, setShowSecondInfo] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [companyData, setCompanyData] = useState<UserPageResponse>()
  const [newCompanyData, setNewCompanyData] = useState<UserPageResponse>(companyData!)
  const [newHeadquarters, setNewHeadquarters] = useState<Set<Headquarter>>()

  const handleEdit = (field : string, event?: ChangeEvent<HTMLInputElement>, index?: number, headquarters?: Set<Headquarter>, salesValue?: string) => {
    if (event){
      if (field.includes('.')) {
        const fields = field.split('.')
        if (fields[0] === 'companyInfo') {
          setNewCompanyData((prev) => ({
            ...prev,
            companyInfo: {...prev.companyInfo, [fields[1]]: (event.target! as HTMLInputElement).value}
          }))
        } else if (fields[0] === 'headquarter' && index !== -1) {
          if (fields[1] === 'operativeOfficeAddress') {
            Array.from(headquarters!)[index!].operativeOfficeAddress = (event.target! as HTMLInputElement).value
          }
          if (fields[1] === 'operativeOfficeTelephone') {
            Array.from(headquarters!)[index!].operativeOfficeTelephone = (event.target! as HTMLInputElement).value
          }
          setNewHeadquarters(headquarters!)
        }
      } else setNewCompanyData((prev) => ({...prev, [field]: (event.target! as HTMLInputElement).value}))
    } else setNewCompanyData((prev) => ({
      ...prev,
      companyInfo: {...prev.companyInfo, salesRange: Number(salesValue!)}
    }))
  }

  const handleSave = () => {
    const newUserPageResponse : UserPageResponse = newCompanyData
    console.log(newUserPageResponse)
    newUserPageResponse.headquarters = newHeadquarters!
    setCompanyInfoByUser(newUserPageResponse)!.then(response => {
      if (response.status === 200) {
        window.location.reload()
      } else {
        console.log('error', response)
      }
    })
  }

  useEffect(() => {
    getCompanyInfoByUser()!.then(response => {
      setCompanyData(response)
      setNewCompanyData(response)
      setNewHeadquarters(response.headquarters)
    })
  }, []);

  return (
      <UserContainer>
        <Title text={t('user-title')}/>
        <ProfileHeader companyData={newCompanyData}/>
        <MainInfo edit={edit}
                  companyData={newCompanyData}
                  editCompanyData={(field, event) => {
                    handleEdit(field, event)
                  }}
        />
        <ExpandContainer $show={!showSecondInfo}>
          <Label text={t('Espandi e modifica')}
                 color={Color.BLUE}
                 fontSize={'13px'}
          />
          <ExpandButton>

            <Button color={Color.BLUE}
                    icon={Icons.COMMONS.ARROW}
                    onClick={() => setShowSecondInfo(true)}
            />
          </ExpandButton>
        </ExpandContainer>
        <SecondInfo show={showSecondInfo}
                    edit={edit}
                    companyData={newCompanyData}
                    editCompanyData={(field, event, salesValue) => handleEdit(field, event, undefined, undefined, salesValue)}
        />
        <Headquarters show={showSecondInfo}
                      edit={edit}
                      companyData={newCompanyData}
                      editCompanyData={(field, event, index, headquarters) => handleEdit(field, event, index, headquarters)}
        />
        <EditButtonGroup $show={showSecondInfo}>
          <Button color={Color.BLUE}
                  textColor={Color.WHITE}
                  label={t('reserved-courses-detail-button-save')}
                  onClick={() => handleSave()}
          />
          <Button color={Color.WHITE}
                  textColor={Color.LIGHT_BLUE}
                  label={t('reserved-courses-detail-button-edit')}
                  onClick={() => setEdit(!edit)}
          />
        </EditButtonGroup>
      </UserContainer>
  );
}

export default UserPage