import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {BreakpointsQuery} from "../../constants/device";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import {Icons} from "../../constants/icons";
import {useAuth} from "../../provider/authProvider";
import UserMenu from "./user-menu";
import {SEMPREFORMATI_LANDING_URL} from "../../utils/config";


const NavShadow = styled.div`
    box-shadow: 0 6px 3px -3px ${Color.SHADOW};
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    position: sticky;
    top: 0;
    background-color: ${Color.WHITE};
    z-index: 100;
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: center;
    z-index: 100;
    height: 8.5rem;
    column-gap: 30px;
    width: 1240px;
    text-align: center;
    @media ${BreakpointsQuery.tablet} {
        column-gap: 12px;
    }
`;

const NavLogoContainer = styled(NavLink)`
    display: inline-flex;
    height: 140px;
    @media ${BreakpointsQuery.tablet} {

    }
`;

const NavLogo = styled.img`

`;

const NavButton = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${Color.LIGHT_BLUE};
    height: 100px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bolder;
    font-family: "Avenir Next Bold", sans-serif;
    font-size: 16px;
`;

const NavUser = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    top: 35px;

`;

const NavBar = () => {
    const {t} = useTranslation();
    const {token} = useAuth();

    return (
        <NavShadow>
            <NavContainer>
                <NavLogoContainer to={SEMPREFORMATI_LANDING_URL} target="_blank" rel="noopener noreferrer">
                    <NavLogo alt={'logo'} src={Icons.LOGO.NAVBAR}/>
                </NavLogoContainer>
                <NavButton to={'/dashboard'}>{t('menu-dashboard')}</NavButton>
                <NavButton to={'/corsi-prenotati'}>{t('menu-reserved-courses')}</NavButton>
                <NavButton to={'/attestati'}>{t('menu-certificate-management')}</NavButton>
                <NavButton to={'/prenota-un-corso'}>{t('menu-book-course')}</NavButton>
                { token &&
                    <NavUser>
                        <UserMenu/>
                    </NavUser>
                }
            </NavContainer>
        </NavShadow>
    );
};

export default NavBar