import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Label from "../../label";
import Textbox from "../../form/input";
import { Color } from "../../../constants/color";
import { BreakpointsQuery } from "../../../constants/device";
import { UserPageResponse } from "../../../types";
import Button from "../../button/button";
import { Icons } from "../../../constants/icons";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { axiosInstance } from "../../../provider/authProvider";
import { BE_BASE_URL } from "../../../utils/config";
import { AxiosError } from "axios";
import { AxiosResponse } from "axios";
import { Trash2, Download } from "lucide-react";
import { set } from "date-fns";

interface Props {
  edit: boolean;
  companyData: UserPageResponse | undefined;
  editCompanyData: (
    field: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
}

const Spacer = styled.div`
  content: "";
  height: 60px;
  border-radius: 18px;
  row-gap: 0.4rem;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 80px;
  margin-top: 30px;
  input {
    background-color: ${Color.WHITE};
    box-shadow: none;
    border-style: solid;
    border-width: 2px;
    border-color: ${Color.LIGHT_BLUE};
    border-radius: 20px;
    width: 480px;
    height: 30px;
    @media ${BreakpointsQuery.tablet} {
      width: 400px;
    }
  }
  input:focus {
    outline-width: 0;
  }
  @media ${BreakpointsQuery.tablet} {
    column-gap: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const UploadFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto 2rem;
  width: 100%;
  gap: 3rem;
`;
const UploadDVRContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 45%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const UploadOrgChartContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 45%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

interface NameContainerProps extends React.HTMLAttributes<HTMLSpanElement> {
  alt?: string;
}

const NameWrapper: React.FC<NameContainerProps> = ({
  alt,
  children,
  ...props
}) => (
  <NameContainer title={alt} {...props}>
    {children}
  </NameContainer>
);

const NameContainer = styled.span<NameContainerProps>`
  width: 33%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.LIGHT_BLUE};
  font-weight: bold;
  font-family: inherit;
  font-size: 0.85rem;
`;

const DownloadDeleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

const MainInfo = ({ edit, companyData, editCompanyData }: Props) => {
  const { t } = useTranslation();
  const [dvrFile, setDvrFile] = useState<File | null>(null);
  const [orgChartFile, setOrgChartFile] = useState<File | null>(null);
  const dvrFileInputRef = useRef<HTMLInputElement | null>(null);
  const orgChartFileInputRef = useRef<HTMLInputElement | null>(null);
  const [isDvrUploaded, setIsDvrUploaded] = useState(false);
  const [isOrgChartUploaded, setIsOrgChartUploaded] = useState(false);

  useEffect(() => {
    fetchDVRName();
    fetchOrgChartName();
  }, []);

  const handleDvrFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.files) {
      const uploadedFile = target.files[0];
      setDvrFile(uploadedFile);
    }
  };

  const handleOrgChartFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.files) {
      const uploadedFile = target.files[0];
      setOrgChartFile(uploadedFile);
    }
  };

  const handleDvrClickRef = () => {
    if (dvrFileInputRef.current) {
      dvrFileInputRef.current.click();
    }
  };

  const handleOrgChartClickRef = () => {
    if (orgChartFileInputRef.current) {
      orgChartFileInputRef.current.click();
    }
  };

  interface ErrorResponse {
    message?: string;
  }

  const handleFileProcess = async (
    event: React.MouseEvent<HTMLButtonElement>,
    docType: string
  ) => {
    event.preventDefault();

    let fileToUpload: File | null = null;

    // Determina quale file deve essere processato e quale endpoint deve essere usato
    if (docType === "dvr") {
      fileToUpload = dvrFile;
    } else if (docType === "orgChart") {
      fileToUpload = orgChartFile;
    } else {
      alert("Invalid document type.");
      return;
    }

    if (fileToUpload) {
      try {
        const formData = new FormData();
        formData.append("file", fileToUpload);
        formData.append("docType", docType);
        await axiosInstance.patch(`${BE_BASE_URL}/company`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Documento caricato con successo");

        // Aggiorna lo stato dopo il caricamento
        if (docType === "dvr") {
          fetchDVRName();
        } else if (docType === "orgChart") {
          fetchOrgChartName();
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        let errorMessage = "Errore durante il salvataggio del file.";
        if (axiosError.response) {
          const data = axiosError.response.data as ErrorResponse;
          if (data && data.message) {
            errorMessage = data.message;
          }
        } else if (axiosError.message) {
          errorMessage = axiosError.message;
        }
        alert(errorMessage);
      }
    } else {
      alert("Nessun file selezionato.");
    }
  };

  const fetchDVRName = async () => {
    try {
      const axiosResponse = await axiosInstance.get(
        `${BE_BASE_URL}/company/dvr-name`
      );
      if (axiosResponse.status === 200) {
        setIsDvrUploaded(true);
        const fileName = axiosResponse.data;
        setDvrFile({ name: fileName } as File);
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il recupero dei documenti.");
    }
  };

  const fetchOrgChartName = async () => {
    try {
      const axiosResponse = await axiosInstance.get(
        `${BE_BASE_URL}/company/orgChart-name`
      );
      if (axiosResponse.status === 200) {
        setIsOrgChartUploaded(true);
        const fileName = axiosResponse.data;
        setOrgChartFile({ name: fileName } as File);
      }
    } catch (e) {
      const error = e as AxiosError;
      alert(error.message || "Errore durante il recupero dei documenti.");
    }
  };

  const handleDownload = async (docType: string) => {
    try {
      let response: AxiosResponse<Blob> | undefined;
      if (docType === "dvr") {
        response = await axiosInstance({
          url: BE_BASE_URL + `/company/download?docType=dvr`,
          method: "GET",
          responseType: "blob",
        });
      } else if (docType === "orgChart") {
        response = await axiosInstance({
          url: BE_BASE_URL + `/company/download?docType=orgChart`,
          method: "GET",
          responseType: "blob",
        });
      }

      if (!response) {
        throw new Error(
          "Response is undefined. Please check the document type."
        );
      }

      let filename = "default_filename.ext";
      const disposition = response.headers["content-disposition"];

      if (disposition) {
        const matches = disposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Errore durante il download del documento:", error);
    }
  };

  const handleDelete = async (docType: string) => {
    if (window.confirm("Sei sicuro di voler eliminare il documento?")) {
      let url = "";
      if (docType === "dvr") {
        url = BE_BASE_URL + "/company/dvr";
      } else if (docType === "orgChart") {
        url = BE_BASE_URL + "/company/orgChart";
      }
      try {
        await axiosInstance.delete(url);
        if (docType === "dvr") {
          setIsDvrUploaded(false);
          setDvrFile(null);
          if (dvrFileInputRef.current) {
            dvrFileInputRef.current.value = "";
          }
        } else if (docType === "orgChart") {
          setIsOrgChartUploaded(false);
          setOrgChartFile(null);
          if (orgChartFileInputRef.current) {
            orgChartFileInputRef.current.value = "";
          }
        }
        alert("Documento eliminato con successo.");
      } catch (e) {
        const error = e as AxiosError;
        alert(error.message || "Errore durante il recupero dei documenti.");
      }
    }
  };

  return (
    <MainContainer>
      <UploadFilesContainer>
        <UploadDVRContainer>
          <Label
            text={t("user-main-info-dvr-label")}
            style={{ width: "35%" }}
          ></Label>
          <NameWrapper alt={dvrFile?.name}>{dvrFile?.name}</NameWrapper>
          {!isDvrUploaded && !dvrFile?.name && (
            <Button
              id="file-upload"
              label={t("user-main-info-select-button")}
              color={Color.BLUE}
              icon={Icons.BUTTONS.SEARCH}
              style={{ width: "25%" }}
              onClick={handleDvrClickRef}
            />
          )}
          <input
            id="file-upload"
            type="file"
            ref={dvrFileInputRef}
            style={{ display: "none" }}
            onChange={handleDvrFileUpload}
          />
          {dvrFile?.name && !isDvrUploaded && (
            <Button
              id="file-process"
              label={t("user-main-info-upload-button")}
              color={Color.BLUE}
              icon={Icons.BUTTONS.UPLOAD}
              style={{ width: "25%" }}
              onClick={(event) => handleFileProcess(event, "dvr")}
            />
          )}
          <DownloadDeleteContainer>
            {isDvrUploaded && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexShrink: "0",
                }}
                onClick={() => handleDownload("dvr")}
              >
                <Download width={22} height={22} />
              </button>
            )}
            {isDvrUploaded && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexShrink: "0",
                }}
                onClick={() => handleDelete("dvr")}
              >
                <Trash2 width={22} height={22} />
              </button>
            )}
          </DownloadDeleteContainer>
        </UploadDVRContainer>
        <UploadOrgChartContainer>
          <Label
            text={t("user-main-info-org-chart-label")}
            style={{ width: "35%" }}
          ></Label>
          <NameWrapper alt={orgChartFile?.name}>
            {orgChartFile?.name}
          </NameWrapper>
          {!isOrgChartUploaded && !orgChartFile?.name && (
            <Button
              label={t("user-main-info-select-button")}
              color={Color.BLUE}
              icon={Icons.BUTTONS.SEARCH}
              style={{ width: "25%" }}
              onClick={handleOrgChartClickRef}
            />
          )}
          <input
            id="file-upload"
            type="file"
            ref={orgChartFileInputRef}
            style={{ display: "none" }}
            onChange={handleOrgChartFileUpload}
          />
          {orgChartFile?.name && !isOrgChartUploaded && (
            <Button
              id="file-process"
              label={t("user-main-info-upload-button")}
              color={Color.BLUE}
              icon={Icons.BUTTONS.UPLOAD}
              style={{ width: "25%" }}
              onClick={(event) => handleFileProcess(event, "orgChart")}
            />
          )}
          <DownloadDeleteContainer>
            {isOrgChartUploaded && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "blue",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexShrink: "0",
                }}
                onClick={() => handleDownload("orgChart")}
              >
                <Download width={22} height={22} />
              </button>
            )}
            {isOrgChartUploaded && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexShrink: "0",
                }}
                onClick={() => handleDelete("orgChart")}
              >
                <Trash2 width={22} height={22} />
              </button>
            )}
          </DownloadDeleteContainer>
        </UploadOrgChartContainer>
      </UploadFilesContainer>
      <Label
        text={t("user-main-info-label")}
        fontSize={"26px"}
        fontWeight={"bold"}
        textTransform={"uppercase"}
      />
      <InfoContainer>
        <Column>
          <Textbox
            label={t("user-business-name")}
            alt={t("user-business-name")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-business-name")}
            placeholder={companyData ? companyData.businessName : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("businessName", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-contact-first-name")}
            alt={t("user-contact-first-name")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-contact-first-name")}
            placeholder={companyData ? companyData.adminFirstName : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("adminFirstName", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-contact-phone")}
            alt={t("user-contact-phone")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-contact-phone")}
            placeholder={companyData ? companyData.adminPhone : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("adminPhone", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-sdi-code")}
            alt={t("user-sdi-code")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-sdi-code")}
            placeholder={companyData ? companyData.companyInfo.sdiCode : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("companyInfo.sdiCode", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-subscription-type")}
            alt={t("user-subscription-type")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-subscription-type")}
            placeholder={companyData ? companyData.subscriptionType : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("subscriptionType", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-rescue-answer-1")}
            alt={t("user-rescue-answer-1")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-rescue-answer-1")}
            placeholder={companyData ? companyData.rescueAnswer1 : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("rescueAnswer1", e);
            }}
            required={false}
            edit={edit}
          />
        </Column>
        <Column>
          <Textbox
            label={t("user-fiscal-code")}
            alt={t("user-fiscal-code")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-fiscal-code")}
            placeholder={companyData ? companyData.vatNumber : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("companyInfo.vatNumber", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-contact-last-name")}
            alt={t("user-contact-last-name")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-contact-last-name")}
            placeholder={companyData ? companyData.adminLastName : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("adminLastName", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-email")}
            alt={t("user-email")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-email")}
            placeholder={companyData ? companyData.adminEmail : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("adminEmail", e);
            }}
            required={false}
            edit={edit}
          />
          <Textbox
            label={t("user-cem")}
            alt={t("user-cem")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-cem")}
            placeholder={companyData ? companyData.cem : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("cem", e);
            }}
            required={false}
            edit={edit}
          />
          <Spacer></Spacer>
          <Textbox
            label={t("user-rescue-answer-2")}
            alt={t("user-rescue-answer-2")}
            autocomplete={"off"}
            form={"company-info"}
            name={t("user-rescue-answer-2")}
            placeholder={companyData ? companyData.rescueAnswer2 : ""}
            type={"text"}
            textAlign={"left"}
            onChange={(e) => {
              editCompanyData("rescueAnswer2", e);
            }}
            required={false}
            edit={edit}
          />
        </Column>
      </InfoContainer>
    </MainContainer>
  );
};

export default MainInfo;
