import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {UserType} from "../../types";


const initialState: UserType = {
  email: "",
  firstName: "",
  id: 0,
  lastName: "",
  phone: "",
  role: 0,
  username: ""
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUser: (state : UserType, action: PayloadAction<UserType>) => {
      return state = action.payload
    }
  }
})

export const {storeUser} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
