import "./reset.css";
import React from "react";
import type { ComponentPropsWithoutRef } from "react";

export default function ResetCss({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & ComponentPropsWithoutRef<"div">) {
  return (
    <div role="reset" {...rest}>
      {children}
    </div>
  );
}
