import React, { useEffect } from "react";
import * as Ariakit from "@ariakit/react";
import { useDebounce } from "@uidotdev/usehooks";
import {
  Bell,
  CalendarDays,
  ChevronDown,
  FileDown,
  Search,
  User,
} from "lucide-react";
import styled from "styled-components";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";

import { BE_BASE_URL, ATTESTATO_DOC_TYPE_ID } from "../../utils/config";
import {
  dateOptionsNumeric,
  getCertificateExpireState,
  getLocale,
  goToPage,
} from "../../utils/utils";

import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";
import { axiosInstance, useAuth } from "../../provider/authProvider";
import { DocumentData, InfoDocumentale } from "../../types";
import { useAppDispatch } from "../../app/hooks";
import { storeEmployees } from "../../features/employee/employeeSlice";
import { isMatch, isValid } from "date-fns";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

//Definisco mappa sedi
const sedeAttestati: { [key: string]: string } = {
  "": "TUTTE",
  GENOVA: "GENOVA",
  SAVONA: "SAVONA",
  IMPERIA: "IMPERIA",
};

// Definisco una mappa con lo stato attestati:
// 1 = valido
// 2 = in scadenza
// 3 = scaduto
// 4 = mancanti
const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
  4: "MANCANTI",
};

type FiltersType = {
  discente: string;
  sede: string;
  stato: number;
};

type DiscenteType = {
  name: string;
  surname: string;
  cf: string;
  headquarter: string;
  date_exp: string;
  status_expire: number;
};

export default function RicercaPerDipendente() {
  const { token } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const filterStatus = urlParams.get("status") || 0;

  const [attestati, setAttestati] = React.useState<Map<string, DocumentData[]>>(
    new Map()
  );
  const [discenti, setDiscenti] = React.useState<Map<string, DiscenteType>>(
    new Map()
  );

  const [filters, setFilters] = React.useState<FiltersType>({
    discente: "",
    sede: "",
    stato: +filterStatus,
  });

  const debouncedFilters = useDebounce(filters, 300);

  useEffect(() => {
    async function fetchAttestati(filters: FiltersType) {
      const url = `${BE_BASE_URL}/certificates`;
      const params = new URLSearchParams();
      params.append("page", "1");
      params.append("size", "1000");
      params.append(
        "order",
        "cognome_discente,nome_discente,cf_discente,inizio_corso"
      );
      params.append("dTId", ATTESTATO_DOC_TYPE_ID.toString());
      const paramFilter = [];
      if (filters.discente) {
        paramFilter.push(
          `(cognome_discente:LIKE:$${filters.discente} OR nome_discente:LIKE:$${filters.discente})`
        );
      }
      if (filters.sede) {
        paramFilter.push(`sede_azienda:EQ:$${filters.sede}`);
      }
      if (paramFilter.length > 0) {
        params.append("filter", paramFilter.join(" AND "));
      }
      const result = await axiosInstance.get<InfoDocumentale>(
        `${url}?${params.toString()}`
      );
      const cfCorsiMap: Map<string, DiscenteType> = new Map();
      const corsiMap: Map<string, DocumentData[]> = new Map();
      if (result.data && result.data.ok) {
        const corsi: DocumentData[] = result.data.content.data;
        // console.log("Attestati ricerca per dipendente: ", corsi);
        corsi.forEach((c: DocumentData) => {
          const metadata = c.metadata;
          //console.log("Attestato ricerca dipendente: ", metadata);
          const statusExpire = getCertificateExpireState(c.metadata);
          // Filtro stato attestato lo possiamo fare solo in pagina per ora
          // il documentale non supporta filtri su campi calcolati
          // SE ho il filtro definito e lo stato è differente non và visualizzato
          if (
            debouncedFilters.stato &&
            statusExpire !== debouncedFilters.stato
          ) {
            return;
          }
          if (corsiMap.has(metadata.cf_discente)) {
            let cDataArray = corsiMap.get(metadata.cf_discente);
            if (cDataArray) {
              cDataArray.push(c);
            } else {
              cDataArray = [c];
            }
            corsiMap.set(metadata.cf_discente, cDataArray);
            // Se la scadenza memorizzata nel discente è più recente di quella del corso
            // allora la aggiorno con quella del corso
            const discente = cfCorsiMap.get(metadata.cf_discente);
            if (discente && new Date(discente.date_exp) > new Date()) {
              // Aggiorno i valori
              discente.date_exp = new Date(
                c.metadata.data_scadenza
              ).toLocaleDateString(getLocale(), dateOptionsNumeric);
              discente.status_expire = statusExpire;
              // Aggiorno il discente nella mappa
              cfCorsiMap.set(metadata.cf_discente, discente);
            }
          } else {
            const discente = {
              name: metadata.nome_discente,
              surname: metadata.cognome_discente,
              cf: metadata.cf_discente,
              headquarter: metadata.sede_azienda,
              date_exp:
                statusExpire !== 4
                  ? new Date(c.metadata.data_scadenza).toLocaleDateString(
                      getLocale(),
                      dateOptionsNumeric
                    )
                  : "",
              status_expire: statusExpire,
            };
            cfCorsiMap.set(metadata.cf_discente, discente);
            corsiMap.set(metadata.cf_discente, [c]);
          }
        });
      }
      return { corsiMap, cfCorsiMap };
    }

    fetchAttestati(debouncedFilters).then(({ corsiMap, cfCorsiMap }) => {
      // console.log("Attestati filtrati: ", corsiMap, cfCorsiMap);
      setAttestati(corsiMap);
      // Ordino la mappa dei discenti in base al cognome
      const sortedDiscenti = new Map(
        Array.from(cfCorsiMap).sort((a, b) =>
          a[1].surname.localeCompare(b[1].surname)
        )
      );
      setDiscenti(sortedDiscenti);
    });
  }, [debouncedFilters, token]);

  const selectSede = Ariakit.useSelectStore({
    value: filters.sede,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, sede: value }));
    },
  });

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchDiscente">NOME O COGNOME</label>
          <div
            style={{
              position: "relative",
              minWidth: "400px",
            }}
          >
            <input
              name="searchDiscente"
              type="text"
              placeholder="Ricerca nome o cognome"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, discente: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SelectProvider store={selectSede}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              SEDE AZIENDALE
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => sedeAttestati[`${value}`]}
            >
              {Object.entries(sedeAttestati).map(([key, value]) => (
                <SelectItem key={`sedeAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
        <SelectProvider store={selectStato}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              STATO
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => statoAttestati[+value]}
            >
              {Object.entries(statoAttestati).map(([key, value]) => (
                <SelectItem key={`statoAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
      </SearchContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "-1.5rem",
            fontSize: ".8rem",
            fontWeight: "bold",
            color: Color.LIGHT_BLUE,
          }}
        >
          <p
            style={{
              width: "110px",
            }}
          ></p>
          <p
            style={{
              width: "480px",
            }}
          >
            NOME E COGNOME
          </p>
          <p
            style={{
              width: "220px",
            }}
          >
            CODICE FISCALE
          </p>
          <p
            style={{
              width: "220px",
            }}
          >
            SEDE AZIENDALE
          </p>
          <p>ATTESTATI</p>
        </div>
        {discenti && discenti.size === 0 && (
          <p
            style={{
              fontSize: "1.5rem",
              marginTop: "4rem",
            }}
          >
            Nessun corso trovato
          </p>
        )}
        {discenti &&
          discenti.size > 0 &&
          Array.from(discenti.entries()).map(([key, value]) => {
            return (
              <div
                key={key}
                style={{
                  width: "100%",
                  filter: "drop-shadow(4px -4px 5px var(--shadow800))",
                }}
              >
                <CourseAccordion
                  icon={<User color={Color.LIGHT_BLUE} size={48} />}
                  key={value.name}
                  name={`${value.name} ${value.surname}`}
                  cf={value.cf}
                  headquarter={value.headquarter}
                  status={value.status_expire}
                  dateExp={value.date_exp}
                  attestati={attestati.get(key) || []}
                />
              </div>
            );
          })}
      </div>
    </Layout>
  );
}

const Disclosure = styled(Ariakit.Disclosure)`
  border-radius: 0.8rem;
  background-color: ${Color.WHITE};
  width: 100%;
  position: relative;
  overflow: hidden;
  border: none;
  padding: 0.5rem 1.5rem;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 1rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  & p[role='button'] {
    position: absolute;
    right: 0;
    top: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${Color.GREEN};
    color: ${Color.WHITE};
    font-weight: bold;
`;

const DisclosureContent = styled(Ariakit.DisclosureContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: calc(100% - 0.8rem);
  margin: -8px auto 0 auto;

  background-color: ${Color.CERAMIC};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding: 1.6rem 2rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: auto 150px 200px 150px 120px 60px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

function CourseAccordion({
  icon,
  name,
  cf,
  headquarter,
  status,
  dateExp,
  attestati,
}: {
  icon?: React.ReactNode;
  name: string;
  cf: string;
  headquarter: string;
  status: number;
  dateExp: string;
  attestati: DocumentData[];
}) {
  const { token } = useAuth();
  const dispatch = useAppDispatch();

  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [searchCorso, setSearchCorso] = React.useState("");
  const debauncedSearchCorso = useDebounce(searchCorso, 300);

  useEffect(() => {
    const filtered = attestati;
    if (debauncedSearchCorso) {
      setFilteredAttestati(
        attestati.filter((a) =>
          a.metadata.name
            .toLowerCase()
            .includes(debauncedSearchCorso.toLowerCase())
        )
      );
    }
    // Ordino per data di scadenza se null la metto in cima
    //TODO to fix
    filtered.sort((a, b) => {
      if (!a.metadata.data_scadenza) {
        return -1;
      }
      if (!b.metadata.data_scadenza) {
        return 1;
      }
      return (
        new Date(a.metadata.data_scadenza).getTime() -
        new Date(b.metadata.data_scadenza).getTime()
      );
    });
    setFilteredAttestati(filtered);
  }, [debauncedSearchCorso, attestati, token]);

  function renderScadenza(status_expire: number, dateExp: string) {
    let missingExpire = isValid(dateExp);
    if (missingExpire) {
      missingExpire = !isMatch(dateExp, "dd/MM/yyyy");
    }
    if (missingExpire) {
      console.log("Data scadenza mancante", dateExp);
    }

    const color =
      status_expire === 1
        ? Color.GREEN
        : status_expire === 2
        ? Color.YELLOW
        : status_expire === 3
        ? Color.RED
        : status_expire === 4
        ? Color.RED
        : "transparent";
    const label =
      status_expire === 1
        ? missingExpire
          ? "VALIDO "
          : "VALIDO FINO AL "
        : status_expire === 2
        ? "SCADE IL "
        : status_expire === 3
        ? "SCADUTO IL "
        : status_expire === 4
        ? "MANCANTE"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} fill={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{label}</p>
          {status_expire !== 4 && !missingExpire && <p>{dateExp}</p>}
        </div>
      </div>
    );
  }

  function renderInfo(attestato: DocumentData) {
    const status: number =
      attestato.metadata.data_prenotazione !== undefined ? 2 : 1;
    const label =
      status === 1
        ? ""
        : status === 2
        ? "PRENOTATO IL " +
          new Date(attestato.metadata.data_prenotazione).toLocaleDateString(
            getLocale(),
            dateOptionsNumeric
          )
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  async function handleDownloadAttestato(id: number, fileName: string) {
    const url = `${BE_BASE_URL}/certificates/${id}`;
    const response = await axiosInstance({
      url,
      method: "GET",
      responseType: "blob",
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  // console.log("Ricerca per dipendente", { name, dateExp, attestati });
  return (
    <>
      <Ariakit.DisclosureProvider>
        <Disclosure>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "450px",
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                fontFamily: "Avenir Next Bold",
              }}
            >
              {icon}
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: Color.LIGHT_BLUE,
                }}
              >
                {name}
              </p>
            </div>
            <p
              style={{
                width: "220px",
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: Color.DARK_GREY,
              }}
            >
              {cf}
            </p>
            <p
              style={{
                width: "100px",
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: Color.DARK_GREY,
              }}
            >
              {headquarter}
            </p>
            <div
              style={{
                width: "180px",
                fontSize: "1rem",
                marginRight: "4.5rem",
              }}
            >
              {renderScadenza(status, dateExp)}
            </div>
            <p role="button">
              <ChevronDown color={Color.WHITE} height={32} width={32} />
            </p>
          </div>
        </Disclosure>
        <DisclosureContent>
          <SearchInput style={{ width: "90%" }}>
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                name="searchPartecipantiCorso"
                type="text"
                placeholder="Ricerca i partecipanti"
                onChange={(e) => setSearchCorso(e.target.value)}
              />
              <SearchButton>
                <Search color={Color.WHITE} size={16} width={16} />
              </SearchButton>
            </div>
          </SearchInput>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <TableAttestatiHeader>
              <p
                style={{
                  width: "430px",
                }}
              >
                Corso
              </p>
              <p>Descrizione</p>
              <p>Stato</p>
              <p>Info</p>
              <p
                style={{
                  justifySelf: "center",
                }}
              >
                Attestati
              </p>
              <p></p>
            </TableAttestatiHeader>
            {filterdAttestati.map((a) => (
              <TableAttestatiRow key={`attestato_${a.id}`}>
                <p
                  style={{
                    fontSize: "1rem",
                    width: "430px",
                  }}
                >
                  {a.metadata.titolo_corso}
                </p>
                <p>{a.metadata.descrizione_corso}</p>
                <p>
                  {renderScadenza(
                    getCertificateExpireState(a.metadata),
                    new Date(a.metadata.data_scadenza).toLocaleDateString(
                      getLocale(),
                      dateOptionsNumeric
                    )
                  )}
                </p>
                <p>{renderInfo(a)}</p>
                {a.fileName ? (
                  <FileDown
                    color={Color.LIGHT_BLUE}
                    width={24}
                    height={24}
                    style={{
                      justifySelf: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDownloadAttestato(a.id, a.fileName)}
                  />
                ) : (
                  <p></p>
                )}

                <div
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(storeEmployees([cf.toUpperCase()]));
                    goToPage(
                      "corso",
                      `${a.metadata.codice_corso.toLowerCase()}/-1`
                    );
                  }}
                >
                  <CalendarDays color={Color.BLUE} />
                </div>
              </TableAttestatiRow>
            ))}
          </div>
        </DisclosureContent>
      </Ariakit.DisclosureProvider>
    </>
  );
}
