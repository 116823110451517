import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";
import {selectUser} from "../../features/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {revalidateToken} from "../../features/user/utils";



export const ProtectedRoute = () => {
    const { token, setToken } = useAuth();
    const dispatch = useAppDispatch()
    const userStore = useAppSelector(selectUser);

    if (!token) {
        return <Navigate to="/login" />;
    } else if (userStore.firstName.length === 0) {
        revalidateToken(setToken, dispatch).then((e) => {
            if (e !== 200) {
                return <Navigate to="/login" />;
            }
            return <Outlet />;
        })
    }
    return <Outlet />;
};