import {AxiosError} from "axios";
import {Employee} from "../../types";
import {BE_BASE_URL, BE_EMPLOYEE_ENDPOINT} from "../../utils/config";
import {format} from "date-fns";
import {axiosInstance} from "../../provider/authProvider";
import {goToPage} from "../../utils/utils";

export function getEmployeeByCompanyId(){
  try {
    const axiosResponse = axiosInstance.get<Employee[]>(BE_BASE_URL + BE_EMPLOYEE_ENDPOINT + 'byCompanyId')
    return axiosResponse.then(response => response.data)
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}

export function getEmployeeForReservationByRangeDate(courseId: string, dateSet: Date[]) {
  try {
    const axiosResponse = axiosInstance.post<Employee[]>(BE_BASE_URL + BE_EMPLOYEE_ENDPOINT + 'forReservationByRangeDate', {
      courseId: courseId,
      dateSet: dateSet,
    })
    return axiosResponse.then(response => response.data)
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}

export function formatDate(date: Date | undefined, pattern: string ): string {
  return date ? format(date, pattern) : "";
}
