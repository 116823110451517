import React from "react";
import styled from "styled-components";
import {CourseCarouselItem} from "../../../types";
import CarouselItem from "./item";
import {BreakpointsQuery} from "../../../constants/device";

interface Props {
    courses: CourseCarouselItem[];
}

const CarouselWrapper = styled.div`
    display: flex;
    transition: display 2s;
    flex-direction: row;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.4rem;
    width: 60rem;
    min-height: fit-content;
    @media ${BreakpointsQuery.tablet} {
        width: 52rem;
    }
`;




const CourseList = ( { courses } : Props ) => {
  const courseList : CourseCarouselItem[] = [];
  courses.forEach(course => {
    let skip = false
    courseList.forEach(el => {
      if (el.courseName === course.courseName) {
        skip = true;
        return
      }
    })
    if (!skip) courseList.push(course)
  })
    return (
        <CarouselWrapper>
            <ListWrapper>
                {courseList.map((singleCourse, index) => {
                    return (
                        <CarouselItem
                            item={singleCourse}
                            key={index}
                        />
                    );
                })}
            </ListWrapper>
        </CarouselWrapper>
    );
};

export default CourseList