import React from "react";
import * as Ariakit from "@ariakit/react";
import styled from "styled-components";
import RicercaPerCorso from "./RicercaPerCorso";
import Title from "../title";
import ResetCss from "../ResetCss";

import "./Tab.css";
import RicercaPerDipendente from "./RicercaPerDipendente";
import RicercaPerScadenza from "./RicercaPerScadenza";
import PrenotaCorsoSede from "./PrenotaCorsoSede";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4rem;
  filter: drop-shadow(0px -4px 5px var(--shadow500));
  margin-top: 2rem;
`;

const TabContentContainer = styled.div`
  padding: 1em 2em;
  width: "1300px";
  background-color: var(--white);
  border-radius: 1rem;
  min-height: 300px;
`;

export default function AttestatiPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const activeTab = urlParams.get("tab") || "corso";
  // console.log("activeTab", activeTab);

  const tab = Ariakit.useTabStore();
  tab.select(activeTab);

  return (
    <ResetCss>
      <Title text="Gestione corsi" />
      <Layout>
        <Ariakit.TabProvider store={tab}>
          <Ariakit.TabList className="tab-list" aria-label="corsi">
            <Ariakit.Tab className="tab" id={"corso"}>
              CERCA PER CORSO
            </Ariakit.Tab>
            <Ariakit.Tab className="tab" id={"dipendente"}>
              CERCA PER DIPENDENTE
            </Ariakit.Tab>
            <Ariakit.Tab className="tab" id={"scadenza"}>
              CERCA PER SCADENZA
            </Ariakit.Tab>
            <Ariakit.Tab className="tab" id={"prenota"}>
              RICHIEDI UN CORSO DEDICATO
            </Ariakit.Tab>
          </Ariakit.TabList>
          <TabContentContainer>
            <Ariakit.TabPanel tabId={"corso"}>
              <RicercaPerCorso />
            </Ariakit.TabPanel>
            <Ariakit.TabPanel tabId={"dipendente"}>
              <RicercaPerDipendente />
            </Ariakit.TabPanel>
            <Ariakit.TabPanel tabId={"scadenza"}>
              <RicercaPerScadenza />
            </Ariakit.TabPanel>
            <Ariakit.TabPanel tabId={"prenota"}>
              <PrenotaCorsoSede />
            </Ariakit.TabPanel>
          </TabContentContainer>
        </Ariakit.TabProvider>
      </Layout>
    </ResetCss>
  );
}
