import Cancel from "../resources/icon/buttons/x.svg"
import Edit from "../resources/icon/buttons/edit.svg"
import DeleteButton from "../resources/icon/buttons/delete-button.svg"
import Download from "../resources/icon/buttons/download.svg"
import Plus from "../resources/icon/buttons/plus.svg"
import Reset from "../resources/icon/buttons/reset.svg"
import Search from "../resources/icon/buttons/search.svg"
import Upload from "../resources/icon/buttons/upload.svg"
import Arrow from "../resources/icon/commons/arrow.svg"
import BuildingGreen from "../resources/icon/commons/building.svg"
import Calendar from "../resources/icon/commons/calendar-days.svg"
import CalendarGreen from "../resources/icon/commons/calendar-days-green.svg"
import Clock from "../resources/icon/commons/clock.svg"
import ClockAlert from "../resources/icon/commons/clock-alert.svg"
import Info from "../resources/icon/commons/info.svg"
import NotebookGreen from "../resources/icon/commons/notebook-pen.svg"
import MapPinGreen from "../resources/icon/commons/map-pin-green.svg"
import User from "../resources/icon/commons/user.svg"
import Address from "../resources/icon/contacts/address.svg"
import Email from "../resources/icon/contacts/email.svg"
import Phone from "../resources/icon/contacts/phone.svg"
import Antincendio from "../resources/icon/course-types/Antincendio.svg"
import PrimoSoccorso from "../resources/icon/course-types/primo_soccorso.png"
import Piattaforme from "../resources/icon/course-types/piattaforme.png"
import Rischio from "../resources/icon/course-types/rischio.png"
import Spazi from "../resources/icon/course-types/spazi.png"
import Presence from "../resources/icon/lecture-modes/presenza.svg"
import Videoconference from "../resources/icon/lecture-modes/videoconferenza.svg"
import E_Learning from "../resources/icon/lecture-modes/e-learning.svg"
import NavBar from "../resources/icon/logo/navbar.svg"
import Footer from "../resources/icon/logo/footer.svg"
import Facebook from "../resources/icon/social/facebook.svg"
import Instagram from "../resources/icon/social/instagram.svg"
import Linkedin from "../resources/icon/social/linkedin.svg"
import Youtube from "../resources/icon/social/youtube.svg"

export const Icons = {
    BUTTONS: {
        CANCEL: Cancel,
        EDIT: Edit,
        DELETE: DeleteButton,
        DOWNLOAD: Download,
        PLUS: Plus,
        RESET: Reset,
        SEARCH: Search,
        UPLOAD: Upload
    },
    COMMONS: {
        ARROW: Arrow,
        BUILDINGGREEN: BuildingGreen,
        CALENDAR: Calendar,
        CALENDARGREEN: CalendarGreen,
        CLOCK: Clock,
        CLOCKALERT: ClockAlert,
        INFO: Info,
        NOTEBOOKGREEN: NotebookGreen,
        MAPPINGREEN: MapPinGreen,
        USER: User
    },
    CONTACTS: {
        ADDRESS: Address,
        EMAIL: Email,
        PHONE: Phone
    },
    COURSE_TYPES: {
        ANTINCENDIO: Antincendio,
        PRIMOSOCCORSO: PrimoSoccorso,
        PIATTAFORME: Piattaforme,
        RISCHIO: Rischio,
        SPAZI: Spazi
    },
    LECTURE_MODES: {
        PRESENCE: Presence,
        VIDEOCONFERENCE: Videoconference,
        E_LEARNING: E_Learning
    },
    LOGO: {
        NAVBAR: NavBar,
        FOOTER: Footer
    },
    SOCIAL: {
        FACEBOOK: Facebook,
        INSTAGRAM: Instagram,
        LINKEDIN: Linkedin,
        YOUTUBE: Youtube
    },
};