import React from "react";
import {CourseDate} from "../../../../types";
import styled from "styled-components";
import {Color} from "../../../../constants/color";
import Label from "../../../label";
import {
  dateOptionDayOnly,
  dateOptionsMonthOnly,
  dateOptionWeekDayOnly,
  getCourseMode,
  getDateDuration,
  getLocale
} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";

interface Props {
    date: CourseDate[],
    index: number,
    selected: boolean,
    onClick: (id: number) => void
}

const ItemWrapper = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 26px;
    width: 160px;
    box-shadow: 0 0 20px 10px ${Color.SHADOW};
    background-color: ${Color.WHITE};
    height: 14rem;
    border-style: solid;
    border-width: 1px;
    border-color: ${Color.BLUE};
    padding: 0;
`;

const InfoArrow = styled.div <{
  $selected: boolean;
}>`
    display: ${props => props.$selected ? 'unset' : 'none'};
    content: "";
    position: relative;
    top: 2.2rem;
    margin-top: -31px;
    z-index: 1;
    transform: rotate(90deg);
`;

const UpperHalf = styled.div <{
    $selected: boolean
}>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 0.6rem;
    background-color: ${props => props.$selected ? Color.LIGHT_BLUE : Color.WHITE};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    height: 50%;
`;

const LowerHalf = styled.div <{
    $selected: boolean
}>`
    background-color: ${props => props.$selected ? Color.WHITE : Color.LIGHT_BLUE};
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    height: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
    justify-content: center;
    width: 100%;
    div {
        text-align: center;
    }
`;

const CourseDateItem = ( {date, selected, onClick, index} : Props) => {
  const { t } = useTranslation();
    const handleSelected = () => onClick && onClick(index);
    const firstDate = date[0]

    const day : Date = new Date(firstDate.date.toString());
    return (
        <ItemWrapper onClick={handleSelected}>
            <UpperHalf $selected={selected}>
                <Label text={day.toLocaleDateString(getLocale(), dateOptionWeekDayOnly)}
                       fontSize={'16px'}
                       fontWeight={'bolder'}
                       textTransform={'uppercase'}
                       color={selected ? Color.WHITE : Color.LIGHT_BLUE}
                       textAlign={'center'}
                       height={'4px'}
                />
                <Label text={day.toLocaleDateString(getLocale(), dateOptionDayOnly)}
                       fontSize={'50px'}
                       fontWeight={'bolder'}
                       textTransform={'uppercase'}
                       color={selected ? Color.WHITE : Color.LIGHT_BLUE}
                       textAlign={'center'}
                       height={'50px'}
                />
                <Label text={day.toLocaleDateString(getLocale(), dateOptionsMonthOnly)}
                       fontSize={'18px'}
                       fontWeight={'bolder'}
                       textTransform={'uppercase'}
                       color={selected ? Color.WHITE : Color.LIGHT_BLUE}
                       textAlign={'center'}
                />
            </UpperHalf>
            <LowerHalf $selected={selected}>
                <Label text={t(getCourseMode(firstDate.mode))}
                       fontSize={'14px'}
                       fontWeight={'bolder'}
                       textTransform={'uppercase'}
                       color={selected ? Color.LIGHT_BLUE : Color.WHITE}
                       textAlign={'center'}
                />
                <Label text={firstDate.city.toUpperCase()}
                       fontSize={'14px'}
                       fontWeight={'bolder'}
                       textTransform={'capitalize'}
                       color={selected ? Color.LIGHT_BLUE : Color.WHITE}
                       textAlign={'center'}
                />
                <Label text={getDateDuration(date) + ' ' + t('hours')}
                       fontSize={'14px'}
                       fontWeight={'bolder'}
                       textTransform={'uppercase'}
                       color={selected ? Color.LIGHT_BLUE : Color.WHITE}
                       textAlign={'center'}
                />
            </LowerHalf>
          <InfoArrow $selected={selected}/>
        </ItemWrapper>
    );
};

export default CourseDateItem