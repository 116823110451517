import React from "react";
import styled from "styled-components";
import { SubmitButtonProps } from "../../../types";
import { Color } from "../../../constants/color";
import { ComponentPropsWithoutRef } from "react";

const Button = styled.button<{ $color: string }>`
  order: 3;
  position: relative;
  width: max-content;
  /* height: 32px; */
  border-radius: 18px;
  border-style: hidden;
  background-color: ${($color) => $color.$color ?? Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  font-size: 16px;
  font-weight: bolder;
  font-family: "Avenir Next Bold", sans-serif;
  margin: inherit;
  cursor: pointer;
`;

function SubmitButton({
  label,
  form,
  name,
  color,
  ...rest
}: SubmitButtonProps & ComponentPropsWithoutRef<"button">) {
  return (
    <Button type={"submit"} form={form} name={name} $color={color!} {...rest}>
      {label}
    </Button>
  );
}

export default SubmitButton;
