import styled from "styled-components";
import { Color } from "../../../constants/color";
import React, { Fragment, useEffect, useState } from "react";
import {
  Employee,
  EmployeeNominations,
  EmployeeSpecificRisks,
  EmployeeWorkplaces,
  EmployeeEquipments,
  Nomination,
} from "../../../types";
import { BE_BASE_URL } from "../../../utils/config";
import * as Ariakit from "@ariakit/react";
import SubmitButton from "../../form/submit";
import Label from "../../label";
import { Select, SelectItem, SelectProvider } from "../../form/select";
import { Status } from "../../../constants/status";
import { formatDate } from "../../../features/employee/utils";
import Button from "../../button/button";
import { Icons } from "../../../constants/icons";
import { CircleX } from "lucide-react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../provider/authProvider";
import { set } from "date-fns";

const ModalLabel = styled.label`
  font-family: "Avenir Next", sans-serif;
  font-weight: bold;
  color: ${Color.LIGHT_BLUE};
`;

// Definire uno stile per il contenitore dei pulsanti
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  padding-block: 1rem;
`;

const CustomButton = styled(Button)`
  margin: 0 !important;
`;

const BadgeDiv = styled.div`
  border: 1px solid ${Color.BLUE};
  background-color: ${Color.LIGHT_GREY};
  border-radius: 1rem;
  padding: 4px 8px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const ModalWrapper = styled.div<{ $isVisible: boolean }>`
  display: ${(props) => (props.$isVisible ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: ${(props) => (props.$isVisible ? 999 : -1)};
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  overflow-y: auto;
  max-height: 90vh;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Input = styled.input`
  border: 1px solid ${Color.LIGHT_BLUE};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  border: 1px solid ${Color.LIGHT_BLUE};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: inherit;
`;

const selectStyle = {
  "--padBlock": "0.3rem",
  border: "1px solid var(--blue300)",
  borderRadius: "1rem",
  fontSize: "1rem",
} as React.CSSProperties;

const selectNominationsStyle = {
  "--padBlock": "0.3rem",
  border: "1px solid var(--blue300)",
  borderRadius: "1rem",
  fontSize: "1rem",
  width: "27.3rem",
} as React.CSSProperties;

interface ModalEmployeeEditProps {
  isVisible: boolean;
  employee: Employee | null;
  closeModal: () => void;
  isNewEmployee: boolean;
}

const ModalEmployeeEdit = ({
  isVisible,
  employee,
  closeModal,
  isNewEmployee,
}: ModalEmployeeEditProps) => {
  console.log("ModalEmployeeEdit", { employee });
  const { t } = useTranslation();
  const [employeeNominations, setEmployeeNominations] = useState<
    EmployeeNominations[]
  >([]);
  const [employeeRisks, setEmployeeRisks] = useState<EmployeeSpecificRisks[]>(
    []
  );
  const [workplaces, setWorkplaces] = useState<EmployeeWorkplaces[]>([]);
  const [equipments, setEquipments] = useState<EmployeeEquipments[]>([]);
  // New state variable for selected state
  const [selectedState, setSelectedState] = useState(
    employee && employee.state ? String(employee.state) : ""
  );
  const [nominationsOptions, setNominationsOptions] = useState<Nomination[]>(
    []
  );
  const [selectedNomination, setSelectedNomination] = useState<string>("");

  const pageSize = 1000;
  const pageNumber = 0;

  useEffect(() => {
    const fetchNominations = async () => {
      try {
        const response = await axiosInstance.get(`${BE_BASE_URL}/nominations`);
        console.log("Nominations response:", response.data);
        setNominationsOptions(response.data);
      } catch (error) {
        console.error("Error fetching nominations", error);
      }
    };

    fetchNominations();
  }, []);

  useEffect(() => {
    // console.log("ModalEmployeeEdit useEffect", { employee });
    if (!isNewEmployee && employee?.id) {
      axiosInstance
        .get(`${BE_BASE_URL}/employee-nominations/${employee.id}`)
        .then((response) => {
          // console.log("Nominations response:", response.data);
          if (response.data && response.data) {
            setEmployeeNominations(response.data);
          } else {
            setEmployeeNominations([]);
          }
        })
        .catch((error) => console.error("Error fetching nominations", error));

      axiosInstance
        .get(
          `${BE_BASE_URL}/specificRisks/${employee.id}?size=${pageSize}&page=${pageNumber}`
        )
        .then((response) => {
          // console.log("Specific Risks response:", response.data);
          if (response.data && response.data.content) {
            setEmployeeRisks(response.data.content);
          } else {
            setEmployeeRisks([]);
          }
        })
        .catch((error) =>
          console.error("Error fetching specific risks", error)
        );
      axiosInstance
        .get(
          `${BE_BASE_URL}/workplaces/${employee.id}?size=${pageSize}&page=${pageNumber}`
        )
        .then((response) => {
          // console.log("Workplaces response:", response.data);
          if (response.data && response.data.content) {
            setWorkplaces(response.data.content);
          } else {
            setWorkplaces([]);
          }
        })
        .catch((error) => console.error("Error fetching workplaces", error));
      axiosInstance
        .get(
          `${BE_BASE_URL}/equipments/${employee.id}?size=${pageSize}&page=${pageNumber}`
        )
        .then((response) => {
          // console.log("Equipment response:", response.data);
          if (response.data && response.data.content) {
            setEquipments(response.data.content);
          } else {
            setEquipments([]);
          }
        })
        .catch((error) => console.error("Error fetching equipment", error));
    } else {
      // When adding a new employee, initialize as empty arrays
      setEmployeeNominations([]);
      setEmployeeRisks([]);
      setWorkplaces([]);
      setEquipments([]);
    }
  }, [employee, isNewEmployee]);

  const handleSelectNomination = () => {
    if (selectedNomination) {
      const selectedOption = nominationsOptions.find(
        (n) => n.name === selectedNomination
      );
      if (selectedOption) {
        setEmployeeNominations((prev) => [
          ...prev,
          { id: selectedOption.id, nomination: selectedOption },
        ]);
        setSelectedNomination(""); // Resetta la selezione dopo l'aggiunta
        nominationStore.setValue(""); // Resetta il select
      }
    }
  };

  const nominationStore = Ariakit.useSelectStore({
    value: selectedNomination,
    setValue: (value) => {
      setSelectedNomination(value);
    },
  });

  const stateStore = Ariakit.useSelectStore({
    value: selectedState,
    setValue: (value) => {
      setSelectedState(value);
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      const employeeData = {
        id: employee?.id,
        state: formData.get("state"),
        firstName: formData.get("firstName"),
        lastName: formData.get("lastName"),
        cf: formData.get("cf"),
        jobDescription: formData.get("jobDescription"),
        workplace: formData.get("workplace"),
        hireDate: formData.get("hireDate"),
        cessationDate: formData.get("cessationDate"),
        email: formData.get("email"),
        phone: formData.get("phone"),
        nominations: employeeNominations,
        specificRisks: employeeRisks,
        workplaces: workplaces,
        equipments: equipments,
        description: formData.get("description"),
      };

      const url = BE_BASE_URL + "/employee";

      if (isNewEmployee) {
        // Exclude 'id' from the payload
        const { ...newEmployeeData } = employeeData;
        await axiosInstance.post(url, newEmployeeData, {
          headers: { "Content-Type": "application/json" },
        });
      } else {
        // Include 'id' when updating an existing employee
        await axiosInstance.put(url, employeeData, {
          headers: { "Content-Type": "application/json" },
        });
      }

      // console.log("Success message", isNewEmployee ? "created" : "updated");
    } catch (error) {
      console.error("Error during submit", error);
    }

    closeModal();
  };

  const handleRemoveNomination = async (nominationId: number) => {
    // console.log("handleRemoveNomination called", nominationId);

    // For new employee, just update the local state
    setEmployeeNominations((prev) => prev.filter((n) => n.id !== nominationId));
  };

  // Funzione per aggiungere un nuovo rischio specifico
  const handleAddSpecificRisk = async (newRisk: string) => {
    if (newRisk.trim() === "") return;

    // For new employee, just update the local state
    setEmployeeRisks((prev) => [
      ...prev,
      { id: Date.now(), specificRisk: newRisk },
    ]);
  };

  const handleKeyDownRischi = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddSpecificRisk(e.currentTarget.value);
      (e.target as HTMLInputElement).value = "";
      (e.target as HTMLInputElement).focus();
    }
  };

  const handleRemoveSpecificRisk = async (riskId: number) => {
    // For new employee, just update the local state
    setEmployeeRisks((prev) => prev.filter((r) => r.id !== riskId));
  };

  const handleAddWorkplaces = async (newWorkplace: string) => {
    if (newWorkplace.trim() === "") return;

    // For new employee, just update the local state
    setWorkplaces((prev) => [
      ...prev,
      { id: Date.now(), workplace: newWorkplace },
    ]);
  };

  const handleKeyDownWorkplaces = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddWorkplaces(e.currentTarget.value);
      (e.target as HTMLInputElement).value = "";
      (e.target as HTMLInputElement).focus();
    }
  };

  const handleRemoveWorkplace = async (workplaceId: number) => {
    // For new employee, just update the local state
    setWorkplaces((prev) => prev.filter((w) => w.id !== workplaceId));
  };

  const handleAddEquipments = async (newEquipment: string) => {
    if (newEquipment.trim() === "") return;

    // For new employee, just update the local state
    setEquipments((prev) => [
      ...prev,
      { id: Date.now(), equipment: newEquipment },
    ]);
  };

  const handleKeyDownEquipments = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddEquipments(e.currentTarget.value);
      (e.target as HTMLInputElement).value = "";
      (e.target as HTMLInputElement).focus();
    }
  };

  const handleRemoveEquipment = async (equipmentId: number) => {
    // For new employee, just update the local state
    setEquipments((prev) => prev.filter((e) => e.id !== equipmentId));
  };

  const renderNominationValue = (value: string | string[]) => {
    if (Array.isArray(value)) {
      const selectedOptions = nominationsOptions.filter((option) =>
        value.includes(option.name)
      );
      return selectedOptions.length > 0
        ? selectedOptions.map((option) => option.name).join(", ")
        : "Seleziona una nomina...";
    } else {
      const selectedOption = nominationsOptions.find(
        (option) => option.name === value
      );
      return selectedOption ? selectedOption.name : "Seleziona una nomina...";
    }
  };

  return (
    <ModalWrapper $isVisible={isVisible}>
      <ModalContent>
        <h2
          style={{
            fontSize: "1.5rem",
            fontWeight: "bolder",
            lineHeight: "1em",
            color: Color.BLUE,
          }}
        >
          {employee?.id
            ? "Modifica dettagli impiegato"
            : "Inserisci dettagli impiegato"}
        </h2>
        {
          <form onSubmit={handleSubmit} id="DipendenteForm">
            <button
              type="submit"
              style={{ display: "none" }}
              disabled
              aria-hidden="true"
            />
            <input type="hidden" name="id" value={employee?.id} />
            <FormWrapper>
              <FormField>
                <ModalLabel htmlFor={"first-name"}>Nome:</ModalLabel>
                <Input
                  type="text"
                  name="firstName"
                  defaultValue={employee?.firstName || ""}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"last-name"}>Cognome:</ModalLabel>
                <Input
                  name="lastName"
                  defaultValue={employee?.lastName}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"fiscal-code"}>Codice fiscale:</ModalLabel>
                <Input name="cf" defaultValue={employee?.cf} required />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"email"}>Email:</ModalLabel>
                <Input
                  name="email"
                  type="email"
                  defaultValue={employee?.email}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"workplace"}>Luogo di lavoro:</ModalLabel>
                <Input
                  name="workplace"
                  defaultValue={employee?.workplace}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"phone"}>Telefono:</ModalLabel>
                <Input
                  name="phone"
                  type="tel"
                  defaultValue={employee?.phone}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"state"}>Stato:</ModalLabel>
                <SelectProvider store={stateStore}>
                  <Select
                    style={selectStyle}
                    name="state"
                    renderValue={(value) => {
                      return value
                        ? Status.get(+value)?.label
                        : "Seleziona uno stato...";
                    }}
                    required
                  >
                    {Array.from(Status.entries()).map(([value, { label }]) => (
                      <SelectItem key={`${value}`} value={`${value}`}>
                        {label}
                      </SelectItem>
                    ))}
                  </Select>
                </SelectProvider>
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"job-description"}>Mansione:</ModalLabel>
                <Input
                  name="jobDescription"
                  defaultValue={employee?.jobDescription}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"hire-date"}>
                  Data di assunzione:
                </ModalLabel>
                <Input
                  name="hireDate"
                  type="date"
                  defaultValue={formatDate(employee?.hireDate, "yyyy-MM-dd")}
                  required
                />
              </FormField>
              <FormField>
                <ModalLabel htmlFor={"cessation-date"}>
                  Data di cessazione:
                </ModalLabel>
                <Input
                  name="cessationDate"
                  type="date"
                  defaultValue={formatDate(
                    employee?.cessationDate,
                    "yyyy-MM-dd"
                  )}
                />
              </FormField>
            </FormWrapper>

            {/* Sezione per le nomine */}
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h3
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bolder",
                    lineHeight: "1em",
                    color: Color.LIGHT_BLUE,
                  }}
                >
                  Nomine
                </h3>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <SelectProvider store={nominationStore}>
                    <Select
                      style={selectNominationsStyle}
                      name="nomination"
                      renderValue={renderNominationValue}
                    >
                      {nominationsOptions.map((nomination) => (
                        <SelectItem
                          key={nomination.id}
                          value={nomination.name}
                          disabled={employeeNominations.some(
                            (n) => n.nomination.name === nomination.name
                          )}
                        >
                          {nomination.name}
                        </SelectItem>
                      ))}
                    </Select>
                  </SelectProvider>
                  <CustomButton
                    label="Salva"
                    color={Color.GREEN}
                    icon={Icons.BUTTONS.PLUS}
                    onClick={handleSelectNomination}
                    textTransform={"capitalize"}
                  ></CustomButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {employeeNominations.map((nomination) => (
                  <BadgeDiv key={nomination.id}>
                    <Label
                      color={Color.DARK_BLUE}
                      text={nomination.nomination.name}
                      fontSize={"14px"}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveNomination(nomination.id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <CircleX width={16} height={16} />
                    </button>
                  </BadgeDiv>
                ))}
              </div>
            </Fragment>

            {/* Sezione per i rischi specifici */}
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h3
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bolder",
                    lineHeight: "1em",
                    color: Color.LIGHT_BLUE,
                  }}
                >
                  Rischi Specifici
                </h3>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Input
                    id="newSpecificRiskId"
                    style={{ minWidth: "400px" }}
                    type="text"
                    placeholder="Nuovo Rischio Specifico"
                    onKeyDown={handleKeyDownRischi}
                  />
                  <Button
                    label="Salva"
                    color={Color.GREEN}
                    icon={Icons.BUTTONS.PLUS}
                    onClick={() => {
                      const inputRisk =
                        document.getElementById("newSpecificRiskId");
                      if (inputRisk) {
                        handleAddSpecificRisk(
                          (inputRisk as HTMLInputElement).value
                        ).then(() => {
                          (inputRisk as HTMLInputElement).value = "";
                          (inputRisk as HTMLInputElement).focus();
                        });
                      }
                    }}
                    textTransform={"capitalize"}
                  ></Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {employeeRisks.map((risk) => (
                  <BadgeDiv key={risk.id}>
                    <Label
                      color={Color.DARK_BLUE}
                      text={risk.specificRisk}
                      fontSize={"14px"}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveSpecificRisk(risk.id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <CircleX width={16} height={16} />
                    </button>
                  </BadgeDiv>
                ))}
              </div>
            </Fragment>

            {/* Sezione per i luoghi di lavoro */}
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h3
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bolder",
                    lineHeight: "1em",
                    color: Color.LIGHT_BLUE,
                  }}
                >
                  Luoghi di Lavoro
                </h3>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Input
                    id="newWorkplaceId"
                    style={{ minWidth: "400px" }}
                    type="text"
                    placeholder="Nuovo Luogo di Lavoro"
                    onKeyDown={handleKeyDownWorkplaces}
                  />
                  <Button
                    label="Salva"
                    color={Color.GREEN}
                    icon={Icons.BUTTONS.PLUS}
                    onClick={() => {
                      const inputRisk =
                        document.getElementById("newWorkplaceId");
                      if (inputRisk) {
                        handleAddWorkplaces(
                          (inputRisk as HTMLInputElement).value
                        ).then(() => {
                          (inputRisk as HTMLInputElement).value = "";
                          (inputRisk as HTMLInputElement).focus();
                        });
                      }
                    }}
                    textTransform={"capitalize"}
                  ></Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {workplaces.map((workplace) => (
                  <BadgeDiv key={workplace.id}>
                    <Label
                      color={Color.DARK_BLUE}
                      text={workplace.workplace}
                      fontSize={"14px"}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveWorkplace(workplace.id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <CircleX width={16} height={16} />
                    </button>
                  </BadgeDiv>
                ))}
              </div>
            </Fragment>

            {/* Sezione per le attrezzature */}
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h3
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bolder",
                    lineHeight: "1em",
                    color: Color.LIGHT_BLUE,
                  }}
                >
                  Attrezzature
                </h3>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Input
                    id="newEquipmentId"
                    style={{ minWidth: "400px" }}
                    type="text"
                    placeholder="Nuova Attrezzatura"
                    onKeyDown={handleKeyDownEquipments}
                  />
                  <Button
                    label="Salva"
                    color={Color.GREEN}
                    icon={Icons.BUTTONS.PLUS}
                    onClick={() => {
                      const inputRisk =
                        document.getElementById("newEquipmentId");
                      if (inputRisk) {
                        handleAddEquipments(
                          (inputRisk as HTMLInputElement).value
                        ).then(() => {
                          (inputRisk as HTMLInputElement).value = "";
                          (inputRisk as HTMLInputElement).focus();
                        });
                      }
                    }}
                    textTransform={"capitalize"}
                  ></Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                {equipments.map((equipment) => (
                  <BadgeDiv key={equipment.id}>
                    <Label
                      color={Color.DARK_BLUE}
                      text={equipment.equipment}
                      fontSize={"14px"}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveEquipment(equipment.id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <CircleX width={16} height={16} />
                    </button>
                  </BadgeDiv>
                ))}
              </div>
            </Fragment>
            <FormField>
              <ModalLabel htmlFor={"description"}>
                Descrizione del lavoro
              </ModalLabel>
              <TextArea
                name="description"
                defaultValue={employee?.description}
              />
            </FormField>
            <ButtonContainer>
              <Button
                onClick={() => {
                  (
                    document.getElementById("DipendenteForm") as HTMLFormElement
                  )?.reset();
                  closeModal();
                }}
                label={t("close")}
                textTransform={"capitalize"}
                color={Color.WHITE}
                textColor={Color.LIGHT_BLUE}
                fontSize={"16px"}
              ></Button>
              <SubmitButton
                label={t("reserved-courses-detail-button-save")}
                form="DipendenteForm"
                color={Color.BLUE}
                name="SubmitButton"
                style={{ padding: "0.5rem 1rem" }}
              />
            </ButtonContainer>
          </form>
        }
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalEmployeeEdit;
