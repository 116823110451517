import {combineReducers, configureStore} from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import {userSlice} from "../features/user/userSlice";
import {employeeSlice} from "../features/employee/employeeSlice";

const persistConfig = {
    key: "root",
    storage: storageSession,
    blacklist: ["id"],
};

const rootReducer = combineReducers({
    user: userSlice.reducer,
    employee: employeeSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = () =>  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const store = setupStore();
// Infer the `RootState`,  `AppDispatch`, and `AppStore` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export const persistor = persistStore(store);