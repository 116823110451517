import React from "react";
import styled from "styled-components";
import ResetPasswordForm from "../../components/form/reset-password";

const ResetContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
`;

const ResetPassword = () => {
  return (
    <ResetContainer>
      <ResetPasswordForm></ResetPasswordForm>
    </ResetContainer>
  );
};

export default ResetPassword