import React, { Fragment, useEffect, useRef, useState } from "react";
import * as Ariakit from "@ariakit/react";
import { Bell, XCircle } from "lucide-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import CorsiBarChart from "./CorsiBarChart";
import { Color } from "../../constants/color";
import ResetCss from "../ResetCss";

import "./dashboardpage.css";
import { BE_BASE_URL, ATTESTATO_DOC_TYPE_ID } from "../../utils/config";
import { DashboardEmployeeData } from "../../types";
import { add, format } from "date-fns";
import { axiosInstance, useAuth } from "../../provider/authProvider";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  row-gap: 2rem;
  column-gap: 1rem;
  justify-content: center;
  padding: 0.5em 1em;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 2px solid var(--blue300);
`;

const Title = styled.h1`
  color: var(--blue300);
  font-size: 40px;
  font-family: "Avenir Next Bold", sans-serif;
  font-weight: bold;
`;

const KpiLayout = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
`;

const KpiLabel = styled.label<{ $fontSize: string }>`
  font-weight: bold;
  font-size: ${(props) => props.$fontSize};
  text-wrap: nowrap;
`;

const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Color.WHITE};
  background-color: ${Color.BLUE};
  border: 0;
  padding: 0.3em 1em;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  padding: 0.2em 1.2em;
`;

const RowLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.6em;
  padding: 0.5em 1em;
  text-align: center;
  align-items: center;
`;

const THead = styled(RowLayout)`
  font-weight: bold;
  text-transform: uppercase;
  color: var(--blue300);
`;

const TRow = styled(RowLayout)`
  color: var(--blue500);
  font-weight: bold;
  text-transform: uppercase;
  text-wrap: balance;
  border: 1px solid var(--grey800);
  border-radius: 8px;
  background-color: var(--grey100);
`;

const TRowButton = styled.div`
  background-color: var(--blue500);
  color: var(--white);
  text-transform: uppercase;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  flex-shrink: 0;
`;

const fabbisognoData = {
  inclusi: [
    {
      title: "SICUREZZA LAVORATORI PARTE GENERALE",
      fabbisogno: 15,
      attivi: 10,
      dafare: 5,
    },
    {
      title: "SICUREZZA LAVORATORI PARTE SPECIFICA RISCHIO BASSO",
      fabbisogno: 7,
      attivi: 6,
      dafare: 1,
    },
    {
      title: "SICUREZZA LAVORATORI PARTE SPECIFICA RISCHIO MEDIO",
      fabbisogno: 10,
      attivi: 8,
      dafare: 2,
    },
  ],
  esclusi: [
    {
      title: "AGGIORNAMENTO SICUREZZA LAVORATORI",
    },
    {
      title: "FORMAZIONE AGGIUNTIVA PER PREPOSTO",
    },
  ],
};

type DipendentiDashboardData = {
  tot: number;
  totMancanti: number;
  sedi: Sede[];
};

type Sede = {
  nome: string;
  dipendenti: number;
  mansioni?: Map<string, Mansione>;
};

type Mansione = {
  nome: string;
  dipendenti: number;
};

export default function DashboardPage() {
  const { token } = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedExpire, setSelectedExpire] = useState(1);
  const kpiData = useRef({
    validi: 0,
    scaduti: 0,
    inScadenza_1m: 0,
    inScadenza_2m: 0,
    inScadenza_6m: 0,
  });

  const [employees, setEmployees] = useState<DipendentiDashboardData | null>(
    null
  );

  const scadenza = format(new Date(), "yyyyMMdd");
  const scadenza1M = format(add(new Date(), { months: 1 }), "yyyyMMdd");
  const scadenza2M = format(add(new Date(), { months: 2 }), "yyyyMMdd");
  const scadenza6M = format(add(new Date(), { months: 6 }), "yyyyMMdd");

  useEffect(() => {
    const fetchData = async () => {
      const [
        responseDashboard,
        responseValidi,
        responseScaduti,
        responseCount1M,
        responseCount2M,
        responseCount6M,
      ] = await Promise.all([
        axiosInstance.get(`${BE_BASE_URL}/employee?dashboard=true`),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:gte:${scadenza}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:lt:${scadenza}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:btw:${scadenza}$,${scadenza1M}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:btw:${scadenza}$,${scadenza2M}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:btw:${scadenza}$,${scadenza6M}`
        ),
      ]);

      if (responseDashboard.status === 200) {
        // console.log(response.data);
        const data = responseDashboard.data as DashboardEmployeeData[];
        const dipendentiData: DipendentiDashboardData = {
          tot: data.length,
          totMancanti: 0,
          sedi: [],
        };
        let currentSede: Sede | null = null;
        data.forEach((employee: DashboardEmployeeData) => {
          dipendentiData.totMancanti += employee.courseMissingList?.length || 0;
          if (
            currentSede === null ||
            currentSede.nome !== employee.workplace.toUpperCase()
          ) {
            currentSede = {
              nome: employee.workplace.toUpperCase(),
              dipendenti: 1,
              mansioni: new Map<string, Mansione>([
                [
                  employee.jobDescription,
                  { nome: employee.jobDescription, dipendenti: 1 },
                ],
              ]),
            };
            dipendentiData.sedi.push(currentSede);
          } else if (currentSede.nome === employee.workplace.toUpperCase()) {
            currentSede.dipendenti++;
            const mansione =
              currentSede.mansioni &&
              currentSede.mansioni.get(employee.jobDescription);
            if (mansione) {
              mansione.dipendenti++;
            } else if (currentSede.mansioni) {
              currentSede.mansioni.set(employee.jobDescription, {
                nome: employee.jobDescription,
                dipendenti: 1,
              });
            }
          }
        });

        setEmployees(dipendentiData);
      }
      if (responseValidi.status === 200) {
        kpiData.current.validi = responseValidi.data.content;
      }
      if (responseScaduti.status === 200) {
        kpiData.current.scaduti = responseScaduti.data.content;
      }
      if (responseCount1M.status === 200) {
        kpiData.current.inScadenza_1m = responseCount1M.data.content;
      }
      if (responseCount2M.status === 200) {
        kpiData.current.inScadenza_2m = responseCount2M.data.content;
      }
      if (responseCount6M.status === 200) {
        kpiData.current.inScadenza_6m = responseCount6M.data.content;
      }
      // console.log({ kpiData: kpiData.current });
    };

    fetchData();
  }, [scadenza, scadenza1M, scadenza2M, scadenza6M, token]);

  function printInScadenza(months: number) {
    switch (months) {
      case 1:
        return kpiData.current.inScadenza_1m;
      case 2:
        return kpiData.current.inScadenza_2m;
      case 6:
        return kpiData.current.inScadenza_6m;
      default:
        return 0;
    }
  }

  return (
    <ResetCss>
      <div
        style={{
          margin: "0 auto",
          width: "1200px",
        }}
      >
        <Layout>
          <div
            style={{
              gridColumn: "1/4",
              alignContent: "center",
              backgroundColor: "var(--gray500)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title>{t("dashboard-title")}</Title>
          </div>
          <Card
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1rem 1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                  color: "var(--blue300)",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "1em",
                  }}
                >
                  <p
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bolder",
                      lineHeight: "1em",
                    }}
                  >
                    {t("dashboard-certificates")}
                  </p>
                </div>
              </div>
              {/* KPI MANCANTI */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor render={<div />}>
                  <KpiLayout $color="var(--red500)">
                    <a
                      href="/attestati?tab=scadenza&status=4"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.RED}
                        width={32}
                        height={32}
                        fill={Color.RED}
                      />
                    </a>
                    <KpiLabel $fontSize="1rem">{t("dashboard-new")}</KpiLabel>
                    <KpiLabel $fontSize="1.2rem">
                      {employees?.totMancanti || 0}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--red500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>
                    {t("dashboard-new")}
                  </Ariakit.HovercardHeading>
                  <p>
                    Numero di corsi da svolgere per la prima volta e per i quali
                    non si hanno attestati oppure corsi necessari per un cambio
                    di mansione
                  </p>
                  <p>
                    Si ricorda che la criticità di questa tipologia è
                    equivalente ad attestati scaduti.
                  </p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
              {/* KPI SCADUTI */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor render={<div />}>
                  <KpiLayout $color="var(--red500)">
                    <a
                      href="/attestati?tab=scadenza&status=3"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.RED}
                        width={32}
                        height={32}
                        fill={Color.RED}
                      />
                    </a>
                    <KpiLabel $fontSize="1rem">
                      {t("dashboard-expired")}
                    </KpiLabel>
                    <KpiLabel $fontSize="1.2rem">
                      {kpiData.current.scaduti}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--red500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>
                    {t("dashboard-expired")}
                  </Ariakit.HovercardHeading>
                  <p>Numero attestati scaduti alla data attuale</p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
              {/* KPI IN SCADENZA */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor render={<div />}>
                  <KpiLayout $color="var(--yellow500)">
                    <a
                      href="/attestati?tab=scadenza&status=2"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.YELLOW}
                        width={32}
                        height={32}
                        fill={Color.YELLOW}
                      />
                    </a>
                    <div
                      style={{
                        display: "flex",
                        gap: ".1em",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <KpiLabel $fontSize="1rem" htmlFor="select_expire">
                        {t("dashboard-expiring")}
                      </KpiLabel>
                      <select
                        id="select_expire"
                        style={{
                          border: "none",
                          color: "var(--yellow500)",
                          fontWeight: "bold",
                          outline: "none",
                        }}
                        onChange={(e) =>
                          setSelectedExpire(parseInt(e.target.value))
                        }
                      >
                        <option value="1">1M</option>
                        <option value="2">2M</option>
                        <option value="6">6M</option>
                      </select>
                    </div>
                    <KpiLabel $fontSize="1rem">
                      {printInScadenza(selectedExpire)}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--yellow500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>{`In scadenza ${selectedExpire}M`}</Ariakit.HovercardHeading>
                  <p>{`Numero attestati in scadenza entro ${
                    selectedExpire === 1 ? "1 mese" : selectedExpire + " mesi"
                  } e per i quali non è ancora stata pianificata il rinnovo`}</p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
              {/* KPI VALIDI */}
              <Ariakit.HovercardProvider>
                <Ariakit.HovercardAnchor render={<div />}>
                  <KpiLayout $color="var(--green500)">
                    <a
                      href="/attestati?tab=scadenza&status=1"
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Bell
                        color={Color.GREEN}
                        width={32}
                        height={32}
                        fill={Color.GREEN}
                      />
                    </a>
                    <KpiLabel $fontSize="1rem">{t("dashboard-valid")}</KpiLabel>
                    <KpiLabel $fontSize="1.2rem">
                      {kpiData.current.validi}
                    </KpiLabel>
                  </KpiLayout>
                </Ariakit.HovercardAnchor>
                <Ariakit.Hovercard
                  style={{
                    backgroundColor: "var(--green500)",
                    color: "var(--white)",
                    padding: "1em",
                    borderRadius: "8px",
                  }}
                >
                  <Ariakit.HovercardHeading>
                    {t("dashboard-valid")}
                  </Ariakit.HovercardHeading>
                  <p>
                    Numero attestati in validità. Il numero comprende gli
                    attestati validi e in scadenza, ma non ancora scaduti
                  </p>
                </Ariakit.Hovercard>
              </Ariakit.HovercardProvider>
            </div>
          </Card>
          <Card>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                padding: "1rem 1rem",
                height: "100%",
                width: "100%",
              }}
            >
              {/*
              <ShieldCheck
                size={48}
                color={Color.GREEN}
                style={{
                  flexShrink: 0,
                }}
              />
              */}
              <p
                style={{
                  fontSize: "2rem",
                  color: Color.LIGHT_BLUE,
                  fontWeight: "bolder",
                  lineHeight: "1em",
                }}
              >
                {t("dashboard-training-needs")}
              </p>
              <OutlineButton onClick={() => setOpen(true)}>
                Visualizza
              </OutlineButton>
              {/* KPI DIPENDENTI */}
              {/*
              <KpiLayout $color="var(--blue500)">
                <User color={Color.BLUE} width={40} height={40} />
                <KpiLabel $fontSize="1rem">{t("dashboard-employees")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.employees}</KpiLabel>
              </KpiLayout>
              */}
              {/* KPI ATTESTATI */}
              {/*
              <KpiLayout $color="var(--yellow500)">
                <ScrollText color={Color.YELLOW} width={40} height={40} />
                <KpiLabel $fontSize="1rem">
                  {t("dashboard-certificates")}
                </KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.certificates}</KpiLabel>
              </KpiLayout>
              */}
              {/* KPI CRITICITA */}
              {/*
              <KpiLayout $color="var(--red500)">
                <TriangleAlert color={Color.RED} width={40} height={40} />
                <KpiLabel $fontSize="1rem">{t("dashboard-criticism")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.expired}</KpiLabel>
              </KpiLayout>
              */}
            </div>
          </Card>

          <Card style={{ gridRow: "3/4", gridColumn: "1/3" }}>
            <DipendentiCard employees={employees} />
          </Card>

          {/* GRAFICI CORSI */}
          <Card style={{ gridRow: "4/5", gridColumn: "1/3" }}>
            <CorsiBarChart chartWidth="100%" chartHeight="300px" />
          </Card>
          {/* GRAFICI FORMAZIONE */}
          {/*
          <Card style={{ gridRow: "4/5", gridColumn: "1/3" }}>
            <FormazioneBarChart chartWidth="100%" chartHeight="300px" />
          </Card>
          */}
          {/* FABBISOGNO FORMATIVO */}
          {/*
          <CardShadowed style={{ gridColumn: "2/3", gridRow: "3/5" }}>
            <FabbisognoFormativo />
          </CardShadowed>
          */}
        </Layout>
      </div>

      <Ariakit.Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="dialog"
      >
        <Ariakit.DialogHeading className="heading">
          Fabbisogno formativo calcolato
          <Ariakit.DialogDismiss
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              top: ".5em",
              right: ".5em",
              cursor: "pointer",
            }}
          >
            <XCircle size={24} color={Color.GREEN} />
          </Ariakit.DialogDismiss>
        </Ariakit.DialogHeading>
        <DialogContent>
          {/* TITOLI */}
          <THead>
            <p></p>
            <p>Fabbisogno formativo</p>
            <p>Attivi</p>
            <p>Da fare</p>
            <p></p>
          </THead>
          {/* ROWS TABLE */}
          {fabbisognoData.inclusi.map((row) => (
            <TRow key={row.title}>
              <p
                style={{
                  textAlign: "left",
                }}
              >
                {row.title}
              </p>
              <p>{row.fabbisogno}</p>
              <p>{row.attivi}</p>
              <p>{row.dafare}</p>
              <TRowButton>DETTAGLI</TRowButton>
            </TRow>
          ))}
          <div>
            <p
              style={{
                color: "var(--blue500)",
                fontSize: "1.3em",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0",
                padding: ".5rem 0",
              }}
            >
              Corsi non inclusi nel tuo pacchetto
            </p>
            <ul
              style={{
                color: "var(--blue500)",
                fontWeight: "bold",
                fontSize: "1em",
                marginBlockEnd: "0",
                marginBlockStart: "0",
                marginLeft: "18em",
                marginBottom: "2em",
              }}
            >
              {fabbisognoData.esclusi.map((row) => (
                <li
                  style={{
                    margin: "0",
                  }}
                  key={row.title}
                >
                  {row.title}
                </li>
              ))}
            </ul>
          </div>
        </DialogContent>
      </Ariakit.Dialog>
    </ResetCss>
  );
}

const DipLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 2rem;
`;

const DipTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  font-size: 1.2rem;
  column-gap: 2rem;
  row-gap: 2rem;
`;

const DipTitle = styled.div`
  color: var(--blue300);
  font-size: 2rem;
  font-weight: bolder;
  line-height: 1em;
`;

const DipTableTitle = styled.p`
  padding: 0.2rem 0;
  font-weight: bold;
`;

const DipTableList = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  padding: 0.2rem 1rem;
  & p[x-data="title"] {
    font-weight: bold;
  }
  & p[x-data="number"] {
    font-variant-numeric: lining-nums;
    text-align: right;
  }
`;

function DipendentiCard({
  employees,
}: {
  employees: DipendentiDashboardData | null;
}) {
  let rowCounter = 0;

  return (
    <DipLayout>
      <DipTable>
        {/* Prima riga titolo piu totale e neoassunti */}
        <DipTitle>Dipendenti</DipTitle>
        <DipTableList
          style={{
            gridColumn: "2/5",
          }}
        >
          <div
            style={{
              gridRow: "1",
              gridColumn: "1/-1",
              borderBottom: "2px dashed var(--grey800)",
              height: "100%",
            }}
          />
          <p
            style={{
              gridRow: "1",
              gridColumn: "1",
            }}
            x-data="title"
          >
            Numero totale
          </p>
          <p
            style={{
              gridRow: "1",
              gridColumn: "2",
            }}
            x-data="number"
          >
            {employees?.tot}
          </p>
        </DipTableList>
        <p></p>
        {/* Seconda riga */}
        {/* Elenco dipendenti per sede */}
        <DipTableTitle
          style={{
            gridColumn: "1",
            gridRow: "2",
            textAlign: "right",
          }}
        >
          SEDE
        </DipTableTitle>
        <DipTableList
          style={{
            gridColumn: "2/5",
            gridRow: "2",
          }}
        >
          <div
            style={{
              display: "grid",
              gridColumn: "1/3",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "0.2rem",
            }}
          >
            {employees?.sedi.map((sede) => {
              rowCounter++;
              return (
                <Fragment key={sede.nome}>
                  <div
                    style={{
                      gridRow: `${rowCounter}`,
                      gridColumn: "1/-1",
                      borderBottom: "2px dashed var(--grey800)",
                    }}
                  />
                  <p
                    x-data="title"
                    style={{
                      gridRow: `${rowCounter}`,
                      gridColumn: "1/2",
                    }}
                  >
                    {sede.nome}
                  </p>
                  <p
                    x-data="number"
                    style={{
                      gridRow: `${rowCounter}`,
                      gridColumn: "3",
                      textAlign: "right",
                    }}
                  >
                    {sede.dipendenti}
                  </p>
                  {sede.mansioni &&
                    Array.from(sede.mansioni.values()).map((mansione) => {
                      rowCounter++;
                      return (
                        <Fragment key={mansione.nome}>
                          <p
                            style={{
                              gridRow: `${rowCounter}`,
                              gridColumn: "2/-1",
                              borderBottom: "2px dashed var(--grey500)",
                            }}
                          />
                          <p
                            x-data="title"
                            style={{
                              gridRow: `${rowCounter}`,
                              gridColumn: "2",
                              color: "var(--grey800)",
                            }}
                          >
                            {mansione.nome}
                          </p>
                          <p
                            x-data="number"
                            style={{
                              gridRow: `${rowCounter}`,
                              gridColumn: "3",
                              textAlign: "right",
                              color: "var(--grey800)",
                            }}
                          >
                            {mansione.dipendenti}
                          </p>
                        </Fragment>
                      );
                    })}
                </Fragment>
              );
            })}
          </div>
        </DipTableList>
      </DipTable>
    </DipLayout>
  );
}
