import React from "react";
import styled from "styled-components";
import SingleEmployee from "./employee";
import Label from "../../label";
import { Color } from "../../../constants/color";
import { BreakpointsQuery } from "../../../constants/device";
import { Employee } from "../../../types";
import { useTranslation } from "react-i18next";

interface Props {
  employees: Employee[];
  showEditModal: (employee: Employee) => void;
}

const TableWrapper = styled.table`
  display: grid;
  flex-direction: column;
  width: 1180px;
  overflow: auto;
  @media ${BreakpointsQuery.tablet} {
    width: 960px;
  }
`;

const TableHeader = styled.td`
  display: grid;
  grid-template-columns: 13rem 13.8rem 13.6rem 14.2rem 11.2rem 5rem;
`;

const HeaderMargin = styled.thead`
  height: 0.2rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  background-color: ${Color.LIGHT_BLUE};
  max-width: inherit;
  @media ${BreakpointsQuery.tablet} {
    width: 1180px;
  }
`;

const ListWrapper = styled.tbody`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  min-width: max-content;
  overflow-x: visible;
  height: 22rem;
  justify-content: flex-start;
`;

const TableRow = styled.tr`
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${Color.LIGHT_GREY};
  }
`;

const EmployeeTable = ({ employees, showEditModal }: Props) => {
  const { t } = useTranslation();
  // const [employees, setEmployees] = useState<Employee[]>(employeeList);

  // Gestisci il click su una riga della tabella
  const handleRowClick = (employee: Employee) => {
    // console.log("Riga cliccata per l'impiegato:", employee);
    showEditModal(employee);
  };

  return (
    <TableWrapper>
      <thead><tr>
        <TableHeader>
        <Label
          text={t("reserved-courses-detail-header-last-name")}
          fontSize={"14px"}
          textTransform={"uppercase"}
          textAlign={"left"}
          width={"max-content"}
        />
        <Label
          text={t("reserved-courses-detail-header-first-name")}
          fontSize={"14px"}
          textTransform={"uppercase"}
          textAlign={"left"}
          width={"max-content"}
        />
        <Label
          text={t("reserved-courses-detail-header-job-description")}
          fontSize={"14px"}
          textTransform={"uppercase"}
          width={"max-content"}
        />
        <Label
          text={t("employee-registry-table-workplace")}
          fontSize={"14px"}
          textTransform={"uppercase"}
          width={"max-content"}
        />
        <Label
          text={t("employee-registry-table-email")}
          fontSize={"14px"}
          textTransform={"uppercase"}
          width={"max-content"}
        />
        <Label
          text={t("employee-registry-table-phone")}
          fontSize={"14px"}
          textTransform={"uppercase"}
          width={"max-content"}
        />
      </TableHeader>
      </tr></thead>
      <HeaderMargin/>
      <ListWrapper>
        {employees &&
          employees.map((singleEmployee, index) => {
            return (
              <TableRow
                key={singleEmployee.lastName + " " + singleEmployee.firstName}
                onClick={() => handleRowClick(singleEmployee)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleRowClick(singleEmployee)
                  }
                }}
                tabIndex={index}
              >
                <SingleEmployee
                  employee={singleEmployee}
                  key={singleEmployee.lastName + " " + singleEmployee.firstName}
                />
              </TableRow>
            );
          })}
      </ListWrapper>
    </TableWrapper>
  );
};

export default EmployeeTable;
