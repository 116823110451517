import Antincendio from "../resources/course-image/antincendio.jpg";
import Ple from "../resources/course-image/ple.jpg";
import Preposto from "../resources/course-image/preposto.jpg";
import PrimoSoccorso from "../resources/course-image/primo-soccorso.jpg";
import Rspp from "../resources/course-image/rspp.jpg";
import Spazi from "../resources/course-image/spazi.jpg";

export const Cover = new Map<string, {name: string; icon: string}>([
  ['ANTINCENDIO', {name: 'ANTINCENDIO', icon: Antincendio}],
  ['PLE', {name: 'PLE', icon: Ple}],
  ['PREPOSTO', {name: 'PREPOSTO', icon: Preposto}],
  ['PRIMOSOCCORSO', {name: 'PRIMO SOCCORSO', icon: PrimoSoccorso}],
  ['SICUREZZA', {name: 'SICUREZZA', icon: Rspp}],
  ['RSPP', {name: 'RSPP', icon: Rspp}],
  ['SPAZI', {name: 'SPAZI', icon: Spazi}],
])