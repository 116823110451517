import React from "react";
import styled from "styled-components";
import LoginForm from "../../components/form/login";
import {NavLink} from "react-router-dom";
import {Color} from "../../constants/color";
import Label from "../../components/label";
import {useTranslation} from "react-i18next";

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
`;

const ForgotPass = styled(NavLink)`
    text-decoration: none;
    order: 2;
    text-align: center;
    margin-top: 2rem
`;

const Login = () => {
    const {t} = useTranslation()
    return (
        <LoginContainer>
            <LoginForm></LoginForm>
            <ForgotPass to={'/reset-password'}  >
                <Label text={t('login-forgot-password')}
                       color={Color.GREEN}
                />
            </ForgotPass>
        </LoginContainer>
    );
};

export default Login