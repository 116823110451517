import styled from "styled-components";
import Textbox from "../input";
import SubmitButton from "../submit";
import {AxiosError} from "axios";
import React, {FormEvent, LegacyRef, useState} from "react";
import {BE_BASE_URL} from "../../../utils/config";
import {useTranslation} from "react-i18next";
import {Color} from "../../../constants/color";
import Label from "../../label";
import {goToPage} from "../../../utils/utils";
import {axiosInstance} from "../../../provider/authProvider";

const FormWrapper = styled.form<{
  $error: boolean;
}>`
  order: 2;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  min-width: 25rem;
  min-height: 480px;
  height: ${(props) => (props.$error ? "530px" : "unset")};
  max-height: 530px;
  margin: 3.2rem auto auto;
  border-radius: 2rem 2rem 2rem 2rem;
  background-color: ${Color.WHITE};
  box-shadow: 0 0 8px 4px #e2e2e2;
    button {
        margin: auto;
    }
`;

const Header = styled.div`
  background-color: ${Color.LIGHT_BLUE};
  border-radius: 2rem 2rem 0 0;
  color: ${Color.WHITE};
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  font-family: "Avenir Next Bold", sans-serif;
  margin: 2px 2px 0 2px;
`;

const ErrorMessage = styled.div<{
  $error: boolean;
}>`
  display: ${(props) => (props.$error ? "unset" : "none")};
  text-align: center;
  max-width: 20rem;
  height: 2rem;
  margin-top: 0.6rem;
  margin-left: 2.5rem;
  background-color: ${Color.PINK};
  border-radius: 0.6rem;
  border-style: solid;
  border-width: 2px;
  border-color: ${Color.RED};
  div {
    margin-top: 0.5rem;
  }
`;

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const formRef :  LegacyRef<HTMLFormElement> | undefined = React.createRef();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [rescueAnswer1, setRescueAnswer1] = useState("");
  const [rescueAnswer2, setRescueAnswer2] = useState("");
  const [newPassword, setNewPassword] = useState("");


  const resetPassword = async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    e.preventDefault();
    if (e.type !== 'submit' && e.type !== 'keyup' ) {
      return
    }
    try {
      await axiosInstance.post(BE_BASE_URL + "/auth/changePassword", {
        email: email,
        rescueAnswer1: rescueAnswer1,
        rescueAnswer2: rescueAnswer2,
        newPassword: newPassword,
      }).then((response) => {
        if (response.status === 200) {
          //TODO feedback message
          goToPage('login')
        }
      })
    } catch (e: unknown) {
      const err = e as AxiosError;
      if (err.response && err.response.data && err.message) {
        console.log("error if", err);
        setError(true);
      } else {
        console.log("error else", err);
        // TODO Handle generic error
        setError(true);
      }
    }
  };

  return (
    <FormWrapper id={"reset-password"}
                 ref={formRef}
                 onSubmit={resetPassword}
                 $error={error}
    >
      <Header>{t("reset-password-header")}</Header>
      <ErrorMessage $error={error}>
        <Label text={t("login-error")} color={Color.RED} fontSize={"13px"} />
      </ErrorMessage>
      <Textbox
        label={t("reset-password-email")}
        alt={"email"}
        form={"reset-password"}
        autocomplete={"email"}
        name={"email"}
        placeholder={t("reset-password-placeholder-email")}
        type={"text"}
        onChange={(e) => setEmail(e.target.value)}
        onKeyUp={(e) => resetPassword(e.nativeEvent)}
      />
      <Textbox
        label={t("reset-password-new")}
        alt={"new password"}
        form={"reset-password"}
        autocomplete={"new-password"}
        name={"password"}
        placeholder={t("login-password-placeholder")}
        type={"password"}
        onChange={(e) => setNewPassword(e.target.value)}
        onKeyUp={(e) => resetPassword(e.nativeEvent)}
      />
      <Textbox
        label={t("user-rescue-answer-1")}
        alt={"rescue-answer-1"}
        form={"reset-password"}
        autocomplete={"off"}
        name={"rescue-answer-1"}
        placeholder={''}
        type={"text"}
        onChange={(e) => setRescueAnswer1(e.target.value)}
        onKeyUp={(e) => resetPassword(e.nativeEvent)}
      />
      <Textbox
        label={t("user-rescue-answer-2")}
        alt={"rescue-answer-2"}
        form={"reset-password"}
        autocomplete={"off"}
        name={"rescue-answer-2"}
        placeholder={''}
        type={"text"}
        onChange={(e) => setRescueAnswer2(e.target.value)}
        onKeyUp={(e) => resetPassword(e.nativeEvent)}
      />
      <SubmitButton
        label={t("reset-password-submit")}
        form={"reset-password"}
        name={"submit reset password"}
      />
    </FormWrapper>
  );
};

export default ResetPasswordForm;
