import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

const initialState: string[] = []

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    storeEmployees: (state, action: PayloadAction<string[]>) => {
      return state = action.payload.map(function(x){ return x.toUpperCase(); })
    }
  }
})

export const {storeEmployees} = employeeSlice.actions;
export const selectEmployees = (state: RootState) => state.employee;
