import React from "react";
import styled from "styled-components";
import {Color} from "../../../constants/color";


const StyledSeparator = styled.div`
    background-color: ${Color.CERAMIC};
    height: 3rem;
    width: 0.3rem;
`;

const ContainerSeparator = () => {
    return (
        <StyledSeparator>
        </StyledSeparator>
    )
}

export default ContainerSeparator