import styled from "styled-components";
import Title from "../../components/title";
import { useTranslation } from "react-i18next";
import { Color } from "../../constants/color";
import {
  BookCourseFilters,
  CourseCarouselItem,
  DropdownOptions,
} from "../../types";
import { BreakpointsQuery } from "../../constants/device";
import CourseList from "../../components/book-course/vertical-list";
import React, { useCallback, useEffect, useState } from "react";
import CustomSearch from "../../components/search";
import * as Ariakit from "@ariakit/react";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../../components/form/select";
import {
  capitalize,
  initCategoryFilter,
  initHeadquartersFilterBook,
  initMonthsFilter,
} from "../../utils/utils";
import { getAvailableCourses } from "../../features/course/utils";

const BookCourseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: center;
`;

const DropdownSection = styled.div`
  display: flex;
  column-gap: 0.8rem;
  background-color: ${Color.BLUE};
  padding: 1rem;
  border-radius: 1rem;
  align-items: flex-end;
  svg {
    position: relative;
    top: 1px;
    right: 2px;
  }
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    row-gap: 1rem;
  }
`;

const BookCourse = () => {
  const { t } = useTranslation();
  const [courseArray, setCourseArray] = useState<CourseCarouselItem[]>();
  const [courseCarouselArray, setCourseCarouselArray] = useState<
    CourseCarouselItem[]
  >(courseArray!);
  const [headquarters, setHeadquarters] = useState<Set<DropdownOptions>>();
  const [categories, setCategories] = useState<Set<DropdownOptions>>();
  const [months, setMonths] = useState<Set<DropdownOptions>>();

  const initPage = useCallback(() => {
    getAvailableCourses()!.then((response) => {
      setCourseArray(response);
      setCourseCarouselArray(response);
      initHeadquartersFilterBook(response, setHeadquarters, t);
      initCategoryFilter(response, setCategories, t);
      initMonthsFilter(setMonths, t);
    });
  }, [t]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  const [filters, setFilters] = React.useState<BookCourseFilters>({
    courseName: "",
    headquarter: t("book-course-filter-all"),
    category: 0,
    month: 0,
  });

  const filterCourse = useCallback(() => {
    let filtered = courseArray!;
    if (filters.courseName !== "") {
      filtered = filtered.filter((el) =>
        el.courseName.toLowerCase().includes(filters.courseName)
      );
    }
    if (filters.headquarter !== t("book-course-filter-all")) {
      filtered = filtered.filter((el) =>
        el.city.toLowerCase().includes(filters.headquarter.toLowerCase())
      );
    }
    if (filters.category !== 0) {
      filtered = filtered.filter((el) => filters.category === el.type);
    }
    if (filters.month !== 0) {
      filtered = filtered.filter(
        (el) => new Date(el.date.toString()).getMonth() + 1 === filters.month
      );
    }
    setCourseCarouselArray(filtered);
  }, [
    courseArray,
    filters.category,
    filters.courseName,
    filters.headquarter,
    filters.month,
    t,
  ]);

  const selectHeadquarter = Ariakit.useSelectStore({
    value: filters.headquarter.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, headquarter: value }));
    },
  });

  const selectCategory = Ariakit.useSelectStore({
    value: filters.category.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, category: Number(value) }));
    },
  });
  const selectMonth = Ariakit.useSelectStore({
    value: filters.month.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, month: Number(value) }));
    },
  });

  useEffect(() => {
    filterCourse();
  }, [filterCourse, filters]);

  // disabled inspection to keep carousel
  // noinspection PointlessBooleanExpressionJS
  return (
    <BookCourseWrapper>
      <Title text={t("book-course-title")} />
      {/* <Label
              text={t('book-course-featured-courses')}
              color={Color.GREEN}
              textAlign={'center'}
              fontSize={'20px'}
              fontWeight={'bold'}
              textTransform={'uppercase'}
          /> */}
      {/* courseCarouselArray !== undefined && <CourseCarousel courses={courseCarouselArray!}/> */}
      {courseCarouselArray && (
        <DropdownSection>
          <CustomSearch
            name={t("book-course-search-keyword")}
            label={t("book-course-search-placeholder-keyword")}
            labelProps={{
              color: Color.WHITE,
              text: t("book-course-search-keyword"),
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            onSearch={(e) => {
              setFilters((prev) => ({ ...prev, courseName: e }));
            }}
          />
          {headquarters && (
            <SelectProvider store={selectHeadquarter}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <SelectLabel
                  style={{
                    paddingLeft: "0.5rem",
                    fontSize: "16px",
                    color: Color.WHITE,
                    textTransform: "uppercase",
                    fontFamily: "Avenir Next Bold, sans-serif",
                  }}
                >
                  {t("book-course-search-headquarter")}
                </SelectLabel>
                <Select
                  style={{
                    minWidth: "200px",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "20px",
                    borderColor: Color.LIGHT_BLUE,
                    padding: "0",
                    paddingLeft: "14px",
                    height: "30px",
                    fontFamily: "Avenir Next Regular, sans-serif",
                  }}
                  renderValue={(value) =>
                    Array.from(headquarters).find(
                      (city) => city.value === value
                    )?.label
                  }
                >
                  {Object.entries(Array.from(headquarters)).map(
                    ([key, value]) => (
                      <SelectItem
                        key={`city_${key}`}
                        value={value.label}
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {value.label}
                      </SelectItem>
                    )
                  )}
                </Select>
              </div>
            </SelectProvider>
          )}
          {categories && (
            <SelectProvider store={selectCategory}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <SelectLabel
                  style={{
                    paddingLeft: "0.5rem",
                    fontSize: "16px",
                    color: Color.WHITE,
                    textTransform: "uppercase",
                    fontFamily: "Avenir Next Bold, sans-serif",
                  }}
                >
                  {t("book-course-search-category")}
                </SelectLabel>
                <Select
                  style={{
                    minWidth: "200px",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "20px",
                    borderColor: Color.LIGHT_BLUE,
                    padding: "0",
                    paddingLeft: "14px",
                    height: "30px",
                    fontFamily: "Avenir Next Regular, sans-serif",
                  }}
                  renderValue={(value) =>
                    Array.from(categories).find((cat) => cat.value === value)
                      ?.label
                  }
                >
                  {Object.entries(Array.from(categories)).map(
                    ([key, value]) => (
                      <SelectItem
                        key={`category_${key}`}
                        value={value.value}
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {value.label}
                      </SelectItem>
                    )
                  )}
                </Select>
              </div>
            </SelectProvider>
          )}
          {months && (
            <SelectProvider store={selectMonth}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <SelectLabel
                  style={{
                    paddingLeft: "0.5rem",
                    fontSize: "16px",
                    color: Color.WHITE,
                    textTransform: "uppercase",
                    fontFamily: "Avenir Next Bold, sans-serif",
                  }}
                >
                  {t("book-course-search-month")}
                </SelectLabel>
                <Select
                  style={{
                    minWidth: "200px",
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderRadius: "20px",
                    borderColor: Color.LIGHT_BLUE,
                    padding: "0",
                    paddingLeft: "14px",
                    height: "30px",
                    fontFamily: "Avenir Next Regular, sans-serif",
                  }}
                  renderValue={(value) =>
                    capitalize(
                      Array.from(months).find((m) => m.value === value)
                        ?.label as string
                    )
                  }
                >
                  {Object.entries(Array.from(months)).map(([key, value]) => (
                    <SelectItem
                      key={`month_${key}`}
                      value={value.value}
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {capitalize(value.label)}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </SelectProvider>
          )}
        </DropdownSection>
      )}
      {courseCarouselArray !== undefined && (
        <CourseList courses={courseCarouselArray} />
      )}
    </BookCourseWrapper>
  );
};

export default BookCourse;
