import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import * as Ariakit from "@ariakit/react";
import { Bell, CalendarDays, FileDown, Search } from "lucide-react";
import {
  Select,
  SelectItem,
  SelectLabel,
  SelectProvider,
} from "../form/select";
import {
  dateOptionsNumeric,
  getCertificateExpireState,
  getLocale,
  goToPage,
} from "../../utils/utils";
import { ATTESTATO_DOC_TYPE_ID, BE_BASE_URL } from "../../utils/config";
import { axiosInstance, useAuth } from "../../provider/authProvider";
import { DocumentData, InfoDocumentale } from "../../types";
import { format, add } from "date-fns";
import { storeEmployees } from "../../features/employee/employeeSlice";
import { useAppDispatch } from "../../app/hooks";
import Button from "../button/button";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 4rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 2rem;
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    gap: 1rem;
  }
`;

const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  & label {
    margin-left: 0.5rem;
    font-family: Avenir Next, sans-serif;
    font-weight: bold;
    color: ${Color.LIGHT_BLUE};
  }
  & input {
    border: 1px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    padding: 0.2rem 1rem;
    width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.WHITE};
  top: 0;
  right: 0;
  bottom: 0;
  width: 2.5rem;
  border-radius: 0 1rem 1rem 0;
`;

const Card = styled.div`
  border-radius: 8px;
  border: 2px solid var(--blue300);
`;

const KpiLayout = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color};
`;

const KpiLabel = styled.label<{ $fontSize: string }>`
  font-weight: bold;
  font-size: ${(props) => props.$fontSize};
`;

//Definisco mappa sedi
const sedeAttestati: { [key: string]: string } = {
  "": "TUTTE",
  GENOVA: "GENOVA",
  SAVONA: "SAVONA",
  IMPERIA: "IMPERIA",
};

// Definisco una mappa con lo stato attestati:
// 1 = valido
// 2 = in scadenza
// 3 = scaduto
// 4 = mancanti
const statoAttestati: { [key: number]: string } = {
  0: "TUTTI",
  1: "VALIDO",
  2: "IN SCADENZA",
  3: "SCADUTO",
  4: "MANCANTI",
};

type filtersType = {
  corso: string;
  sede: string;
  stato: number;
};

export default function RicercaPerScadenza() {
  const { t } = useTranslation();
  const { token } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const filterStatus = urlParams.get("status") || 0;

  const [attestati, setAttestati] = React.useState<DocumentData[]>([]);

  const [filters, setFilters] = React.useState<filtersType>({
    corso: "",
    sede: "",
    stato: +filterStatus,
  });

  const debouncedFilters = useDebounce(filters, 300);
  const [selectedExpire, setSelectedExpire] = useState(1);

  const kpiData = useRef({
    validi: 0,
    scaduti: 0,
    inScadenza_1m: 0,
    inScadenza_2m: 0,
    inScadenza_6m: 0,
  });

  const scadenza = format(new Date(), "yyyyMMdd");
  const scadenza1M = format(add(new Date(), { months: 1 }), "yyyyMMdd");
  const scadenza2M = format(add(new Date(), { months: 2 }), "yyyyMMdd");
  const scadenza6M = format(add(new Date(), { months: 6 }), "yyyyMMdd");

  useEffect(() => {
    const fetchData = async () => {
      const [
        responseValidi,
        responseScaduti,
        responseCount1M,
        responseCount2M,
        responseCount6M,
      ] = await Promise.all([
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:gte:${scadenza}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:lt:${scadenza}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:btw:${scadenza}$,${scadenza1M} AND data_scadenza:gte:${scadenza}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:btw:${scadenza}$,${scadenza2M}`
        ),
        axiosInstance.get(
          `${BE_BASE_URL}/certificates/count?dTId=${ATTESTATO_DOC_TYPE_ID}&filter=data_scadenza:btw:${scadenza}$,${scadenza6M}`
        ),
      ]);

      if (responseValidi.status === 200) {
        kpiData.current.validi = responseValidi.data.content;
      }
      if (responseScaduti.status === 200) {
        kpiData.current.scaduti = responseScaduti.data.content;
      }
      if (responseCount1M.status === 200) {
        kpiData.current.inScadenza_1m = responseCount1M.data.content;
      }
      if (responseCount2M.status === 200) {
        kpiData.current.inScadenza_2m = responseCount2M.data.content;
      }
      if (responseCount6M.status === 200) {
        kpiData.current.inScadenza_6m = responseCount6M.data.content;
      }
      // console.log({ kpiData: kpiData.current });
    };

    fetchData();
  }, [scadenza, scadenza1M, scadenza2M, scadenza6M, token]);

  function printInScadenza(months: number) {
    switch (months) {
      case 1:
        return kpiData.current.inScadenza_1m;
      case 2:
        return kpiData.current.inScadenza_2m;
      case 6:
        return kpiData.current.inScadenza_6m;
      default:
        return 0;
    }
  }

  const totMancanti = useRef(0);

  useEffect(() => {
    async function fetchAttestati(filters: filtersType) {
      const url = `${BE_BASE_URL}/certificates`;
      const params = new URLSearchParams();
      params.append("page", "1");
      params.append("size", "1000");
      params.append(
        "order",
        "cognome_discente,nome_discente,cf_discente,inizio_corso:desc"
      );
      params.append("dTId", ATTESTATO_DOC_TYPE_ID.toString());
      const paramFilter = [];
      if (filters.corso) {
        paramFilter.push(`titolo_corso:like:$${filters.corso}`);
      }
      if (filters.sede) {
        paramFilter.push(`sede_azienda:like:$${filters.sede}`);
      }
      if (paramFilter.length > 0) {
        params.append("filter", paramFilter.join(" AND "));
      }
      const result = await axiosInstance.get<InfoDocumentale>(
        `${url}?${params.toString()}`
      );
      if (result.data && result.data.ok) {
        const corsi: DocumentData[] = result.data.content.data;
        // console.log("Attestati ricerca per scadenza: ", corsi);
        totMancanti.current = 0;
        const attestati = corsi.map((c: DocumentData) => {
          const metadata = c.metadata;
          if (metadata.mancante) {
            totMancanti.current++;
          }
          return c;
        });
        // Filtro stato attestato lo possiamo fare solo in pagina per ora,
        // il documentale non supporta filtri su campi calcolati
        if (debouncedFilters.stato) {
          return attestati.filter(
            (a: DocumentData) =>
              getCertificateExpireState(a.metadata) === debouncedFilters.stato
          );
        }
        return attestati;
      } else {
        console.debug("error empty response");
        return [];
      }
    }

    fetchAttestati(debouncedFilters).then((attestati) => {
      // Ordino su scadenza che è una data (se null va in cima)
      attestati.sort((a: DocumentData, b: DocumentData) => {
        if (!a.metadata.data_scadenza) {
          return -1;
        }
        if (!b.metadata.data_scadenza) {
          return 1;
        }
        return (
          new Date(a.metadata.data_scadenza).getTime() -
          new Date(b.metadata.data_scadenza).getTime()
        );
      });
      // attestati.sort((a: any, b: any) => a.discente.localeCompare(b.discente));
      setAttestati(attestati);
    });
  }, [debouncedFilters, token]);

  const selectSede = Ariakit.useSelectStore({
    value: filters.sede,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, sede: value }));
    },
  });

  const selectStato = Ariakit.useSelectStore({
    value: `${filters.stato}`,
    setValue: (value) => {
      // console.log("Selected value", value);
      setFilters((prev) => ({ ...prev, stato: +value }));
    },
  });

  return (
    <Layout>
      <SearchContainer>
        <SearchInput>
          <label htmlFor="searchCorso">CORSO</label>
          <div
            style={{
              position: "relative",
              minWidth: "600px",
            }}
          >
            <input
              name="searchCorso"
              type="text"
              placeholder="Ricerca corso"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, corso: e.target.value }))
              }
            />
            <SearchButton>
              <Search color={Color.WHITE} size={16} width={16} />
            </SearchButton>
          </div>
        </SearchInput>
        <SelectProvider store={selectSede}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              SEDE AZIENDALE
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => sedeAttestati[`${value}`]}
            >
              {Object.entries(sedeAttestati).map(([key, value]) => (
                <SelectItem key={`sedeAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
        <SelectProvider store={selectStato}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.1rem",
            }}
          >
            <SelectLabel
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              STATO
            </SelectLabel>
            <Select
              style={{
                minWidth: "200px",
              }}
              renderValue={(value) => statoAttestati[+value]}
            >
              {Object.entries(statoAttestati).map(([key, value]) => (
                <SelectItem key={`statoAttestato_${key}`} value={key}>
                  {value}
                </SelectItem>
              ))}
            </Select>
          </div>
        </SelectProvider>
      </SearchContainer>

      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "67rem",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 1rem",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              color: "var(--blue300)",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "bolder",
                  lineHeight: "1em",
                }}
              >
                {t("dashboard-certificates")}
              </p>
            </div>
          </div>
          {/* KPI MANCANTI */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor render={<div />}>
              <KpiLayout $color="var(--red500)">
                <a
                  href="/attestati?tab=scadenza&status=4"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.RED}
                    width={32}
                    height={32}
                    fill={Color.RED}
                  />
                </a>
                <KpiLabel $fontSize="1rem">{t("dashboard-new")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{totMancanti.current}</KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--red500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: 1000,
              }}
            >
              <Ariakit.HovercardHeading>
                {t("dashboard-new")}
              </Ariakit.HovercardHeading>
              <p>
                Numero di corsi da svolgere per la prima volta e per i quali non
                si hanno attestati oppure corsi necessari per un cambio di
                mansione
              </p>
              <p>
                Si ricorda che la criticità di questa tipologia è equivalente ad
                attestati scaduti.
              </p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
          {/* KPI SCADUTI */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor render={<div />}>
              <KpiLayout $color="var(--red500)">
                <a
                  href="/attestati?tab=scadenza&status=3"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.RED}
                    width={32}
                    height={32}
                    fill={Color.RED}
                  />
                </a>
                <KpiLabel $fontSize="1rem">{t("dashboard-expired")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">
                  {kpiData.current.scaduti}
                </KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--red500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: 1000,
              }}
            >
              <Ariakit.HovercardHeading>
                {t("dashboard-expired")}
              </Ariakit.HovercardHeading>
              <p>Numero attestati scaduti alla data attuale</p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
          {/* KPI IN SCADENZA */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor render={<div />}>
              <KpiLayout $color="var(--yellow500)">
                <a
                  href="/attestati?tab=scadenza&status=2"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.YELLOW}
                    width={32}
                    height={32}
                    fill={Color.YELLOW}
                  />
                </a>
                <div
                  style={{
                    display: "flex",
                    gap: ".1em",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <KpiLabel $fontSize="1rem" htmlFor="select_expire">
                    {t("dashboard-expiring")}
                  </KpiLabel>
                  <select
                    id="select_expire"
                    style={{
                      border: "none",
                      color: "var(--yellow500)",
                      fontWeight: "bold",
                      outline: "none",
                    }}
                    onChange={(e) =>
                      setSelectedExpire(parseInt(e.target.value))
                    }
                  >
                    <option value="1">1M</option>
                    <option value="2">2M</option>
                    <option value="6">6M</option>
                  </select>
                </div>
                <KpiLabel $fontSize="1rem">
                  {printInScadenza(selectedExpire)}
                </KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--yellow500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: 1000,
              }}
            >
              <Ariakit.HovercardHeading>{`In scadenza ${selectedExpire}M`}</Ariakit.HovercardHeading>
              <p>{`Numero attestati in scadenza entro ${
                selectedExpire === 1 ? "1 mese" : selectedExpire + " mesi"
              } e per i quali non è ancora stata pianificata il rinnovo`}</p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
          {/* KPI VALIDI */}
          <Ariakit.HovercardProvider>
            <Ariakit.HovercardAnchor render={<div />}>
              <KpiLayout $color="var(--green500)">
                <a
                  href="/attestati?tab=scadenza&status=1"
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Bell
                    color={Color.GREEN}
                    width={32}
                    height={32}
                    fill={Color.GREEN}
                  />
                </a>
                <KpiLabel $fontSize="1rem">{t("dashboard-valid")}</KpiLabel>
                <KpiLabel $fontSize="1.2rem">{kpiData.current.validi}</KpiLabel>
              </KpiLayout>
            </Ariakit.HovercardAnchor>
            <Ariakit.Hovercard
              style={{
                backgroundColor: "var(--green500)",
                color: "var(--white)",
                padding: "1em",
                borderRadius: "8px",
                zIndex: 1000,
              }}
            >
              <Ariakit.HovercardHeading>
                {t("dashboard-valid")}
              </Ariakit.HovercardHeading>
              <p>
                Numero attestati in validità. Il numero comprende gli attestati
                validi e in scadenza, ma non ancora scaduti
              </p>
            </Ariakit.Hovercard>
          </Ariakit.HovercardProvider>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          width: "78rem",
        }}
      >
        <TableCertificates attestati={attestati} />
      </div>
    </Layout>
  );
}

const TableAttestati = styled.div`
  display: grid;
  grid-template-columns: auto 300px 150px 150px 150px 100px 20px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const TableAttestatiHeader = styled(TableAttestati)`
  text-transform: uppercase;
  color: ${Color.LIGHT_BLUE};
`;

const TableAttestatiRow = styled(TableAttestati)`
  border: 1px solid ${Color.GREY};
  border-radius: 16px;
  text-transform: uppercase;
  color: ${Color.DARK_GREY};
  background-color: ${Color.LIGHT_GREY};
  min-height: 3.2rem;
`;

function TableCertificates({ attestati }: { attestati: DocumentData[] }) {
  const dispatch = useAppDispatch();
  const [filterdAttestati, setFilteredAttestati] = React.useState(attestati);
  const [searchPartecipante, setSearchPartecipante] = React.useState("");
  const debauncedSearchPartecipante = useDebounce(searchPartecipante, 300);
  const [selectedRow, setSelectedRow] = React.useState<string[]>([]);

  useEffect(() => {
    if (debauncedSearchPartecipante) {
      setFilteredAttestati(
        attestati.filter(
          (a) =>
            a.metadata.cognome_discente +
            " " +
            a.metadata.nome_discente
              .toLowerCase()
              .includes(debauncedSearchPartecipante.toLowerCase())
        )
      );
    } else {
      setFilteredAttestati(attestati);
    }
  }, [debauncedSearchPartecipante, attestati]);

  function renderScadenza(attestato: DocumentData) {
    const status_expire: number = getCertificateExpireState(attestato.metadata);
    const missingExpire: boolean =
      new Date(attestato.metadata.data_scadenza).toLocaleDateString(
        getLocale(),
        dateOptionsNumeric
      ) === "Invalid Date";
    const color =
      status_expire === 1
        ? Color.GREEN
        : status_expire === 2
        ? Color.YELLOW
        : status_expire === 3
        ? Color.RED
        : status_expire === 4
        ? Color.RED
        : "transparent";
    const label =
      status_expire === 1
        ? missingExpire
          ? "VALIDO "
          : "VALIDO FINO AL "
        : status_expire === 2
        ? "SCADE IL "
        : status_expire === 3
        ? "SCADUTO IL "
        : status_expire === 4
        ? "MANCANTI"
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
        }}
      >
        <Bell color={color} fill={color} width={24} height={24} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".2rem",
            color,
          }}
        >
          <p>{label}</p>
          {status_expire !== 4 && !missingExpire && (
            <p>
              {new Date(attestato.metadata.data_scadenza).toLocaleDateString(
                getLocale(),
                dateOptionsNumeric
              )}
            </p>
          )}
        </div>
      </div>
    );
  }

  function renderInfo(attestato: DocumentData) {
    const status: number =
      attestato.metadata.data_prenotazione !== undefined ? 2 : 1;
    const label =
      status === 1
        ? ""
        : status === 2
        ? "PRENOTATO IL " +
          new Date(attestato.metadata.data_prenotazione).toLocaleDateString(
            getLocale(),
            dateOptionsNumeric
          )
        : "";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".4rem",
          color: Color.BLUE,
        }}
      >
        {status === 2 ? (
          <CalendarDays color={Color.BLUE} width={24} height={24} />
        ) : null}
        <p>{label}</p>
      </div>
    );
  }

  async function handleDownloadAttestato(id: number, fileName: string) {
    const url = `${BE_BASE_URL}/certificates/${id}`;
    const response = await axiosInstance({
      url,
      method: "GET",
      responseType: "blob",
    });
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  function handleSelectRow(checkbox: HTMLInputElement) {
    console.log("Attestato selezionato", checkbox.value);
    if (checkbox.checked) {
      // Ho selezionato un partecipante quindi lo aggiungo alla lista
      setSelectedRow((prev) => [...prev, checkbox.value]);
      const [_, codiceCorso] = checkbox.value.split("_");
      // Disattivo tutti i checkbox legati ad un corso differente
      const allCheckboxes = document.querySelectorAll("input[type=checkbox]");
      allCheckboxes.forEach((cb) => {
        const inputCB = cb as HTMLInputElement;
        inputCB.disabled = !inputCB.id
          .toLowerCase()
          .endsWith(codiceCorso.toLowerCase());
      });
    } else {
      setSelectedRow((prev) => {
        const filtered = prev.filter((row) => row !== checkbox.value);
        if (filtered.length === 0) {
          // Riabilito tutti i checkbox
          const allCheckboxes = document.querySelectorAll(
            "input[type=checkbox]"
          );
          allCheckboxes.forEach((cb) => {
            const chechbox = cb as HTMLInputElement;
            chechbox.disabled = false;
          });
        }
        return filtered;
      });
    }
  }

  function handlePrenotazione() {
    const cfDiscenti = selectedRow.map((row) => row.split("_")[0]);
    const corso = selectedRow[0].split("_")[1];
    // console.log("Prenotazione attestati", { cfDiscenti, corso });
    dispatch(storeEmployees(cfDiscenti));
    goToPage("corso", `${corso.toLowerCase()}/-1`);
  }

  return (
    <>
      <SearchInput style={{ width: "80%" }}>
        <div
          style={{
            position: "relative",
          }}
        >
          <input
            name="searchPartecipantiCorso"
            type="text"
            placeholder="Ricerca i partecipanti"
            onChange={(e) => setSearchPartecipante(e.target.value)}
          />
          <SearchButton>
            <Search color={Color.WHITE} size={16} width={16} />
          </SearchButton>
        </div>
      </SearchInput>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <TableAttestatiHeader>
          <p>Nome e cognome</p>
          <p>Corso</p>
          <p>Sede aziendale</p>
          <p>Stato</p>
          <p>Info</p>
          <p
            style={{
              justifySelf: "center",
            }}
          >
            Attestato
          </p>
        </TableAttestatiHeader>
        {filterdAttestati && filterdAttestati.length === 0 && (
          <p
            style={{
              fontSize: "1.5rem",
              marginTop: "4rem",
              textAlign: "center",
            }}
          >
            Nessun corso trovato
          </p>
        )}
        {filterdAttestati &&
          filterdAttestati.length > 0 &&
          filterdAttestati.map((a) => (
            <TableAttestatiRow key={`attestato_${a.id}`}>
              <p>
                {a.metadata.cognome_discente + " " + a.metadata.nome_discente}
              </p>
              <p>{a.metadata.titolo_corso}</p>
              <p>{a.metadata.sede_azienda}</p>
              <p>{renderScadenza(a)}</p>
              <p>{renderInfo(a)}</p>
              {a.fileName ? (
                <FileDown
                  color={Color.LIGHT_BLUE}
                  width={24}
                  height={24}
                  style={{
                    justifySelf: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDownloadAttestato(a.id, a.fileName)}
                />
              ) : (
                <p></p>
              )}
              {/*
              <p
                style={{
                  justifySelf: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(storeEmployees([a.metadata.cf_discente]));
                    goToPage(
                      "corso",
                      `${a.metadata.codice_corso.toLowerCase()}/-1`
                    );
                  }}
                >
                  <CalendarDays color={Color.BLUE} />
                </div>
              </p>
              */}
              <input
                id={`${a.metadata.cf_discente}_${a.metadata.codice_corso}`}
                type="checkbox"
                value={`${a.metadata.cf_discente}_${a.metadata.codice_corso}`}
                onChange={(e) => handleSelectRow(e.target)}
              />
            </TableAttestatiRow>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <Button
          label="PRENOTA"
          color={Color.LIGHT_BLUE}
          disabled={selectedRow.length === 0}
          onClick={handlePrenotazione}
        />
      </div>
    </>
  );
}
