import React, { ChangeEvent, useState, useRef } from "react";
import { BE_BASE_URL } from "../../utils/config";
import { axiosInstance } from "../../provider/authProvider";
import { AxiosError } from "axios";
import styled from "styled-components";
import Label from "../../components/label";
import Button from "../../components/button/button";
import { Icons } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { CircleX } from "lucide-react";
import { Color } from "../../constants/color";
import Title from "../../components/title";

const UploadContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 45%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
`;

interface NameContainerProps extends React.HTMLAttributes<HTMLSpanElement> {
  alt?: string;
}

const NameWrapper: React.FC<NameContainerProps> = ({
  alt,
  children,
  ...props
}) => (
  <NameContainer title={alt} {...props}>
    {children}
  </NameContainer>
);

const NameContainer = styled.span<NameContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 33%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.LIGHT_BLUE};
  font-weight: bold;
  font-family: inherit;
  font-size: 0.85rem;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TrainingNeedsPage: React.FC = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.files) {
      const uploadedFile = target.files[0];
      setFile(uploadedFile);
    }
  };

  const handleClickRef = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = ".xlsx, xls";
      fileInputRef.current.click();
    }
  };

  interface ErrorResponse {
    message?: string;
  }

  const handleFileProcess = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    let fileToUpload: File | null = null;

    fileToUpload = file;

    if (fileToUpload) {
      try {
        /*
        const formData = new FormData();
        formData.append("file", fileToUpload);
        await axiosInstance.patch(`${BE_BASE_URL}/company`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        **/
        setFile(null);
        alert("Documento caricato con successo");
      } catch (error) {
        const axiosError = error as AxiosError;
        let errorMessage = "Errore durante il salvataggio del file.";
        if (axiosError.response) {
          const data = axiosError.response.data as ErrorResponse;
          if (data && data.message) {
            errorMessage = data.message;
          }
        } else if (axiosError.message) {
          errorMessage = axiosError.message;
        }
        alert(errorMessage);
      }
    } else {
      alert("Nessun file selezionato.");
    }
  };

  return (
    <PageWrapper>
      <Title text={t("user-training-needs-title")} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <UploadContainer>
          <Label
            text={t("user-training-needs-upload-label")}
            style={{ width: "35%" }}
          ></Label>
          <NameWrapper>
            {file?.name}
            {file && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexShrink: "0",
                }}
                onClick={() => {
                  setFile(null);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                }}
              >
                <CircleX width={18} height={18} />
              </button>
            )}
          </NameWrapper>
          <Button
            id="file-upload"
            label={t("user-main-info-select-button")}
            color={Color.GREEN}
            icon={Icons.BUTTONS.SEARCH}
            style={{ width: "25%" }}
            onClick={handleClickRef}
          />
          <input
            id="file-upload"
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <Button
            id="file-process"
            label={t("user-main-info-upload-button")}
            color={Color.BLUE}
            icon={Icons.BUTTONS.UPLOAD}
            style={{ width: "25%" }}
            onClick={handleFileProcess}
          />
        </UploadContainer>
      </div>
    </PageWrapper>
  );
};

export default TrainingNeedsPage;
