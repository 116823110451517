import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import {useState} from "react";
import {Icons} from "../../constants/icons";
import Label from "../label";
import {LabelProps} from "../../types";

interface Props {
    name: string;
    onSearch(input : string): void | undefined
    label?: string;
    width?: string;
    labelProps?: LabelProps
}

const CoursesSearchWrapper = styled.div <{
    $width: string;
}>`
    display: flex;
    flex-direction: column;
    width: ${props => props.$width ?? '80%'};
    height: 54px;
`;

const Row = styled.div`
    display: flex;
    height: 30px;
    margin-top: 2px;
`;

const Search = styled.input`
    border-color: ${Color.LIGHT_BLUE};
    border-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    flex-grow: 8;
    text-align: center;
    outline: none;
    font-family: "Avenir Next Regular", sans-serif;
`;

const ButtonSearch = styled.button`
    flex-grow: 1;
    background-color: ${Color.LIGHT_BLUE};
    color: ${Color.WHITE};
    font-size: 14px;
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    text-transform: uppercase;
    
`;

const CustomSearch = ( {name, onSearch, label, width, labelProps} : Props) => {
    const { t } = useTranslation();
    const [input, setInput] = useState('');
    return (
        <CoursesSearchWrapper $width={width!}>
          <Label
              text={labelProps?.text ?? name}
              color={labelProps?.color ?? Color.WHITE}
              textTransform={labelProps?.textTransform ?? 'uppercase'}
              paddingLeft={13}
              paddingBottom={labelProps?.paddingBottom}
              textAlign={labelProps?.textAlign ?? 'unset'}
              fontWeight={labelProps?.fontWeight ?? 'unset'}
              fontSize={labelProps?.fontSize ?? 'unset'}
              width={labelProps?.width ?? 'unset'}
          />
            <Row>
              <Search
                  name={name}
                  placeholder={label ?? t('reserved-courses-detail-search-placeholder')}
                  onChange={ e => {
                    setInput(e.currentTarget.value)
                    onSearch(e.currentTarget.value)
                  }}
                  onKeyUp={ (e) => {
                      if (e.key === 'Enter') {
                          onSearch(input)
                      }
                  }}
              />
              <ButtonSearch onClick={ () => {
                onSearch(input)
              }}>
                <img
                    src={Icons.BUTTONS.SEARCH}
                    alt={'search'}
                    width={20}
                    style={{
                      position: 'relative',
                      right: '2px',
                      marginTop: '2px'
                    }}
                />
              </ButtonSearch>
            </Row>

        </CoursesSearchWrapper>
    );
};

export default CustomSearch
