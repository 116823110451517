import styled from "styled-components";
import { Color } from "../../constants/color";
import React, { ReactNode } from "react";

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  filter: grayscale(80%);
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  & > div {
    margin-top: 50vh;
    color: ${Color.CERAMIC};
    font-weight: bold;
    font-family: sans-serif;
    font-size: 3rem;
    animation: l1 1.2s linear infinite alternate;
  }

  @keyframes l1 {
    to {
      opacity: 0.5;
    }
  }
`;

function Loader({ children, show }: { children?: ReactNode; show: boolean }) {
  if (!children) {
    children = "Loading...";
  }
  return (
    <LoadingWrapper style={{ display: show ? "flex" : "none" }}>
      <div>{children}</div>
    </LoadingWrapper>
  );
}

export { Loader };
