// noinspection CssUnusedSymbol,CssUnresolvedCustomProperty

import * as Ariakit from "@ariakit/react";
import Label from "../../label";
import { Color } from "../../../constants/color";
import { ChevronDown } from "lucide-react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../provider/authProvider";
import { BreakpointsQuery } from "../../../constants/device";
import React, { useEffect, useState } from "react";
import { storeUser, userSlice } from "../../../features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectUser } from "../../../features/user/userSlice";

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 70px;
  @media ${BreakpointsQuery.tablet} {
    margin-left: 10px;
  }

  .button {
    --border: rgb(0 0 0/13%);
    --highlight: rgb(255 255 255/20%);
    --shadow: rgb(0 0 0/10%);
    display: flex;
    height: 70px;
    width: 70px;
    min-width: 70px;
    user-select: none;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    white-space: nowrap;
    border-radius: 50%;
    border-style: none;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    text-decoration-line: none;
    outline-width: 2px;
    outline-offset: 2px;
    outline-color: hsl(204 100% 40%);
    box-shadow: inset 0 0 0 1px var(--border), inset 0 2px 0 var(--highlight),
      inset 0 -1px 0 var(--shadow), 0 1px 1px var(--shadow);
    font-weight: 500;
  }

  .button:where(.dark, .dark *) {
    --border: rgb(255 255 255/10%);
    --highlight: rgb(255 255 255/5%);
    --shadow: rgb(0 0 0/25%);
    background-color: rgb(255 255 255 / 0.05);
    color: white;
    box-shadow: inset 0 0 0 1px var(--border), inset 0 -1px 0 1px var(--shadow),
      inset 0 1px 0 var(--highlight);
  }

  .button:not(:active):hover {
    --border: rgb(0 0 0/33%);
  }

  .button:where(.dark, .dark *):not(:active):hover {
    --border: rgb(255 255 255/25%);
  }

  .button[aria-disabled="true"] {
    opacity: 0.5;
  }

  .button[data-focus-visible] {
    outline-style: solid;
  }

  .button:active,
  .button[data-active] {
    padding-top: 0.125rem;
    box-shadow: inset 0 0 0 1px var(--border), inset 0 2px 0 var(--border);
  }

  @media (min-width: 640px) {
    .button {
      gap: 0.5rem;
    }
  }

  .button:active:where(.dark, .dark *),
  .button[data-active]:where(.dark, .dark *) {
    box-shadow: inset 0 0 0 1px var(--border), inset 0 1px 1px 1px var(--shadow);
  }

  .separator {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    height: 0;
    width: 100%;
    border-top-width: 1px;
    border-color: ${Color.LIGHT_GREY};
  }

  .separator:where(.dark, .dark *) {
    border-color: hsl(204 4% 28%);
  }

  .menu {
    position: relative;
    right: 145px;
    z-index: 50;
    display: flex;
    max-height: var(--popover-available-height);
    min-width: 200px;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: hsl(204 20% 88%);
    background-color: white;
    padding: 0.5rem;
    color: black;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    outline: none !important;
    @media ${BreakpointsQuery.tablet} {
      right: 0;
    }
  }

  .menu:where(.dark, .dark *) {
    border-color: hsl(204 4% 24%);
    background-color: hsl(204 4% 16%);
    color: white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.25),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  .menu-item {
    display: flex;
    cursor: default;
    scroll-margin: 0.5rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    padding: 0.2rem 0.5rem;
    outline: none !important;
    justify-content: flex-end;

    & > a {
      font-family: "Avenir Next Regular", sans-serif;
      text-decoration: none;
      color: ${Color.LIGHT_BLUE};
    }
  }

  .menu-item[aria-disabled="true"] {
    opacity: 0.25;
  }

  .menu-item[data-active-item] {
    background-color: ${Color.LIGHT_BLUE};
    color: white;

    & > a {
      font-family: "Avenir Next Bold", sans-serif;
      text-decoration: none;
      color: ${Color.WHITE};
    }
  }

  .menu-item:active,
  .menu-item[data-active] {
    background-color: ${Color.BLUE};
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
`;

const AriaMenu = styled(Ariakit.Menu)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-family: "Avenir Next Regular", sans-serif;
`;
const MenuItem = styled(NavLink)`
  width: 100%;
  text-align: right;
`;

const UserMenu = () => {
  const { t } = useTranslation();
  const { token, setToken } = useAuth();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const [showMenu, setShowMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState(useLocation().pathname);

  const useLocationChange = (action: {
    (currentPage: string): void;
    (arg0: string): void;
  }) => {
    const location = useLocation();
    React.useEffect(() => {
      action(location.pathname);
    }, [action, location]);
  };

  useLocationChange((nextPage) => {
    setCurrentPage(nextPage);
  });

  useEffect(() => {
    setShowMenu(false);
  }, [token, currentPage]);

  return (
    <UserContainer
      onBlur={(e) => {
        if (
          e.relatedTarget === null &&
          !e.currentTarget.contains(e.relatedTarget)
        )
          setShowMenu(false);
      }}
    >
      <Ariakit.MenuProvider>
        <Ariakit.MenuButton
          className="button"
          onClick={() => setShowMenu(!showMenu)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderColor: Color.LIGHT_BLUE,
              borderWidth: "3px",
              borderStyle: "solid",
              borderRadius: "50%",
              height: "70px",
              minWidth: "70px",
            }}
          >
            <Label
              text={
                user.firstName !== ""
                  ? user.firstName[0] + user.lastName[0]
                  : ""
              }
              color={Color.LIGHT_BLUE}
              fontSize={"20px"}
              fontWeight={"bolder"}
              height={"6px"}
            />
            <ChevronDown
              color={Color.LIGHT_BLUE}
              style={{
                zIndex: "3",
                clipPath: "polygon(100% 40%, 0 40%, 50% 100%)",
                position: "relative",
                top: "10px",
                height: "30px",
                width: "30px",
              }}
              fill={Color.LIGHT_BLUE}
              onClick={() => {}}
            />
          </div>
        </Ariakit.MenuButton>
        <AriaMenu gutter={12} className="menu" open={showMenu}>
          <Ariakit.MenuItem className="menu-item">
            <MenuItem to={"/user"}>{t("menu-user-page")}</MenuItem>
          </Ariakit.MenuItem>
          <Ariakit.MenuItem className="menu-item">
            <MenuItem to={"/anagrafica-dipendenti"}>
              {t("menu-employee-registry")}
            </MenuItem>
          </Ariakit.MenuItem>
          <Ariakit.MenuItem className="menu-item">
            <MenuItem to={"/previous-certificates"}>
              {t("menu-previous-certificates-page")}
            </MenuItem>
          </Ariakit.MenuItem>
          <Ariakit.MenuItem className="menu-item">
            <MenuItem to={"/training-needs"}>
              {t("menu-training-needs-page")}
            </MenuItem>
          </Ariakit.MenuItem>
          <Ariakit.MenuSeparator className="separator" />
          <Ariakit.MenuItem className="menu-item">
            <MenuItem
              to={""}
              onClick={() => {
                setToken("logout");
                dispatch(storeUser(userSlice.getInitialState()));
                localStorage.clear();
                if (!token) window.location.replace("/login");
              }}
            >
              {t("navbar-logout")}
            </MenuItem>
          </Ariakit.MenuItem>
        </AriaMenu>
      </Ariakit.MenuProvider>
    </UserContainer>
  );
};

export default UserMenu;
